import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { confirmAlert } from "react-confirm-alert";
import "./PendingOrder.css"

function AdminPendingOrderDetails({ match }) {
  const [serviceHistorys, SetServiceHistorys] = useState(
    [
      {
        id: "",
        vendor_id: "",
        user_id: "",
        order_id: "",
        created_at: "",
        updated_at: null,
        zone_id: "",
        service_id: "",
        name: "",
        service_name: "",
        service: "",
        zone: ""
      }
    ]
  );
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [amountCollected, setAmountCollected] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);

  useEffect(() => {
    fetchItem();

  }, []);

  function formatData(q) {
    q.forEach((item) => {
      if (item.status === 0) {
        item.status = "Service Not Started"
      } else if (item.status === 1) {
        item.status = "Complete"
      } else if (item.status === 2) {
        item.status = "On Review"
      } else if (item.status === 4) {
        item.status = "Cancelled"
      } else if (item.status === 7) {
        item.status = "Requested to Redeem"
      } else if (item.status === 8) {
        item.status = "Redeemed"
      } else {
        item.status = "Status Unknown"
      }
    });
    return q
  }

  function GetAmountCollected(data) {
    var amount = data.reduce(function (prev, cur) {
      return parseInt(prev) + parseInt(cur.price * cur.quantity);
    }, 0);

    return amount
  }

  const fetchItem = async () => {
    const link = "https://backend.amaderservice.com/api/assignedServices?orderid=" + match.params.id;

    const data = await fetch(link);
    const item = await data.json();

    if (item.length == 0) {
      setLoading(true)
      history.push("/assigned");
    } else {
      setLoading(false)
    }
    SetServiceHistorys(formatData(item));
    setAmountCollected(GetAmountCollected(item))
    setLoading(false);
  };
  const rowHandler = async (id, status) => {
    if (status !== "Complete") {
      confirmAlert({
        title: "Are you sure?",
        message: "Would you like to assign this service as complete?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              const link = "https://backend.amaderservice.com/api/completeOrder?adminid=1&orderid=" + id;
              fetch(link).then(() => {
                fetchItem()
              })
            }
          },
          {
            label: "No"
          }
        ]
      });
    } else {
      alert("Cannot Set as Complete")
    }


  }

  return (
    <div>

      {loading ?
        <div style={{ margin: 20 }}>
          <h2 style={{ fontSize: "14px", color: "#387cd5" }}>Loading</h2>
        </div>
        :

        <div style={{ margin: 20 }}>
          <h1 onClick={() => history.push("/orders/Cancelled")} style={{ cursor: "pointer" }}>&#x2190;</h1>

          <h3>Cancelled Order Details</h3>
          <Table responsive>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>User ID</th>
                <th>Username</th>
                <th>Contact Person</th>
                <th>Contact Person Mobile</th>
                <th>Order Address</th>
                <th>Expected At</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{serviceHistorys[0].order_id}</td>
                <td >{serviceHistorys[0].user_id}</td>
                <td>{serviceHistorys[0].name}</td>
                <td>{serviceHistorys[0].contact_person}</td>
                <td>{serviceHistorys[0].contact_person_number}</td>
                <td>{serviceHistorys[0].order_address}</td>
                <td >{serviceHistorys[0].expectedTime}</td>
              </tr>
            </tbody>
          </Table>

          <h3 style={{ marginTop: "30px;" }}>Services</h3>
          <Table responsive>
            <thead>
              <tr>
                <th>Status</th>
                <th>Vendor</th>
                <th>Service</th>
                <th>Service Type</th>
                <th>Area</th>
                <th>Unit Price</th>
                <th>Qty</th>
                <th style={{ textAlign: "right" }}>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {serviceHistorys.map((item) => (
                <tr>
                  <td>{item.status}</td>
                  <td>{item.vendor_name}</td>
                  <td>{item.service}</td>
                  <td >{item.service_name}</td>
                  <td>{item.area > 0 ? item.area + " sqft." : "-"}</td>
                  <td>{`৳`} {item.area > 0 ? item.price / item.area : item.price}</td>
                  <td>{item.quantity}</td>
                  <td style={{ textAlign: "right" }}>{`৳`} {parseInt(item.price * item.quantity)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <p style={{ margin: 0, textAlign: "right", marginRight: 10, padding: 0, marginTop: -20, marginBottom: 10 }}>________________________________</p>
          <p style={{ textAlign: "right", marginRight: 10 }}>Service Total {`৳`} {amountCollected}</p>
          <p style={{ textAlign: "right", marginRight: 10, }}>Coupon Discount - {`৳`} {serviceHistorys[0].coupon_discount_amount}</p>
          <p style={{ margin: 0, textAlign: "right", marginRight: 10, padding: 0, marginTop: -20, marginBottom: 10 }}>________________________________</p>
          <p style={{ textAlign: "right", marginRight: 10, fontWeight: "bold" }}>Amount To Collect {`৳`} {amountCollected - serviceHistorys[0].coupon_discount_amount}</p>
          <p style={{ margin: 0, textAlign: "right", marginRight: 10, padding: 0, marginTop: -20, marginBottom: 10 }}>________________________________</p>
        </div>
      }
    </div>
  );
};

export default AdminPendingOrderDetails;
