import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./PendingQuotation.css"
import { Table, Row, Col } from 'react-bootstrap';
import { Modal, Button } from "react-bootstrap";

const AdminPendingOrder = () => {
  const [serviceHistorys, SetServiceHistorys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [Username, setUserName] = useState("");

  useEffect(() => {
    fetchItem();

  }, []);



  const fetchItem = async () => {
    fetch("https://backend.amaderservice.com/api/pendingquotations")
      .then((response) => response.json())
      .then((data) => SetServiceHistorys(data))
      .then(() => setLoading(true));


  };

  const sendEmail = async (userId, serviceId) => {

    fetch("https://backend.amaderservice.com/api/sentquota_email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userid: userId,
        service_id: serviceId,
        message: message,

      }),
    })
      .then((res) => res.json())
      .then((response) => setEmailSent(true))
      .catch((error) => console.error("Error:", error));




  };

  return (
    <div>
      <Modal
        size="lg"
        show={modalShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setModalShow(false)}

      >
        <Modal.Header>

          <h3 className="text" style={{ textAlign: "center" }}>
            Send quotation Email to {Username}
          </h3>
        </Modal.Header>
        <Modal.Body>
          {!emailSent ?
            <div class="form-floating mb-3">
              <textarea
                class="form-control"
                id="floatingPassword"
                type="text"
                rows="8"
                style={{ height: "300px" }}
                placeholder="number"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                required
              ></textarea>
              <label for="floatingPassword">Email Body</label>
            </div>
            :
            <div>
              <h4 style={{ textAlign: "center", color: "green" }}>

                <br />Email successfully sent!
              </h4>
            </div>
          }

        </Modal.Body>
        <Modal.Footer>

          <Button onClick={() => sendEmail(userId, serviceId)}>Send Email</Button>



          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }}>
        <h1 style={{textAlign:"center"}}>Pending Quotations ({serviceHistorys.length})</h1>
        {serviceHistorys.length > 0 ?
          <Table responsive>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>User ID</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Area in sqft.</th>
                <th>Details</th>

                <th>Created At</th>

                <th>Expected At</th>
              </tr>
            </thead>
            <tbody>
              {serviceHistorys.map((item) => (

                <tr onClick={() => {
                  setUserId(item.user_id)
                  setServiceId(item.service_id)
                  setUserName(item.name)
                  setModalShow(true)
                }}
                  style={{ cursor: "pointer" }}>
                  <td >{item.order_id}</td>
                  <td>{item.user_id}</td>
                  <td>{item.name}</td>

                  <td>{item.contact_number}</td>
                  <td>{item.area}</td>
                  <td>{item.description}</td>

                  <td>{item.created_at}</td>

                  <td>{item.expectedTime}</td>


                </tr>


              ))}
            </tbody>
          </Table>
          :
          <h2>(No Pending Quotations at the moment)</h2>
        }
      </div>
    </div>
  );
};

export default AdminPendingOrder;
