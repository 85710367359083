import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import "./PendingOrder.css"
import vendorDashDataFormat from './data';
import axios, { post } from 'axios';
function RegVendorDetails({ match }) {
  const [serviceHistorys, SetServiceHistorys] = useState(vendorDashDataFormat);
  const [vendorServices, setVendorServices] = useState([]);

  const [page, setPage] = useState("Info");
  const [deposit, setDeposit] = useState(null);


  useEffect(() => {
    fetchItem();
    fetchServices();


  }, []);
  const history = useHistory();

  
  function formatData(q) {
    var o = {};
    q.forEach((i) => {
      var id = i.id;
      if (!o[id]) {
        return o[id] = i
      }
      return o[id].zone = o[id].zone.toString() + ", " + i.zone.toString()
    })
    var a2 = []
    Object.keys(o).forEach((key) => {
      a2.push(o[key])
    })


    return a2;

    //zones compiled in a2
/*-----------------------------------------------------------------------------------
    let p = []
    a2.map(async x => {
      //index is used to check if the object pre exists or not
      let index = p.findIndex(w => w.services_id === x.services_id);
      if (index == -1) {
        let y = {
          services_id: x.services_id,
          service: x.service,
          serviceicon: x.serviceicon,
          details: [
            {
              id: x.id,
              price: x.price,
              service_name: x.service_name,
              zone: x.zone
            }
          ]
        }
        p.push(y)
      }
      //if the id already exists, do this
      else {
        let y = {
          id: x.id,
          price: x.price,
          service_name: x.service_name,
          zone: x.zone
        }
        p[index].details.push(y)
      }
    }

    )
    return p
---------------------------------------------------------------------------------------------*/

  }

  const viewTradeLicense = async (url) => {
    if (url === null) {
      alert("Trade License Not Found")
    } else {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
  }

  const submitDeposit = async () => {
    const url = "https://backend.amaderservice.com/api/deposittovendor";
    
    try {
      post(url,  
        {
          vendorid: match.params.id,          
          amount: deposit
        })
      .then(response => {
        setDeposit(null)
        fetchItem();
        setPage("Info")
        alert("success")
      })
    } catch (error) {
      alert("error!")
    }
  };

  const fetchServices = async () => {
    fetch("https://backend.amaderservice.com/api/showVendorServices?vendorid=" + match.params.id)
      .then((response) => response.json())
      .then((data) => { 
        var s = formatData(data);
        console.log(s);
        setVendorServices(s)
      })
  };

  const fetchItem = async () => {
    const link = "https://backend.amaderservice.com/api/Vendor_Dash?vendorid=" + match.params.id;
    const data = await fetch(link);
    const item = await data.json();
    SetServiceHistorys(item);
  };

  return (
    <div>
      <Row style={{ width: "100%", height: 50, backgroundColor: "#060b26", margin: 0, }}>
        <Col onClick={() => setPage("Info")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Info" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Info</h4>
        </Col>
        <Col onClick={() => setPage("Pending")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Pending" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Pending</h4>
        </Col>
        <Col onClick={() => setPage("Completed")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Completed" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Complete</h4>
        </Col>
        <Col onClick={() => setPage("Cancelled")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Cancelled" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Cancelled</h4>
        </Col>
        <Col onClick={() => setPage("Services")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Services" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Services</h4>
        </Col>
        <Col onClick={() => setPage("Deposit")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Deposit" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Deposit</h4>
        </Col>
      </Row>
      <h1 onClick={() => history.push("/vendors")} style={{ cursor: "pointer", margin: 0, marginLeft: 20 }}>&#x2190;</h1>
      {page == "Info" &&

        <div style={{ margin: 20 }}>


          <h1 style={{ textAlign: "center", marginTop: -40, }}>Vendor Info</h1>
          <Table responsive>
            <thead>
              <tr>
                <th>Vendor ID</th>
                <th>Vendor Name</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Address</th>
                <th>Trade License</th>
                <th>Security Deposit</th>
              </tr>
            </thead>
            <tbody>
              <tr >
                <td>{serviceHistorys.primaryinfo[0].id}</td>
                <td>{serviceHistorys.primaryinfo[0].vendor_name}</td>
                <td>{serviceHistorys.primaryinfo[0].contact}</td>
                <td>{serviceHistorys.primaryinfo[0].email}</td>
                <td>{serviceHistorys.primaryinfo[0].address}</td>
                <td onClick={() => viewTradeLicense(serviceHistorys.primaryinfo[0].trade_license)}>{serviceHistorys.primaryinfo[0].trade_license === null ? "N/A" : "View"}</td>
                <td>{serviceHistorys.paymetdetail[0]?.deposit}</td>


              </tr>

            </tbody>
          </Table>

          <Table responsive>
            <thead>
              <tr>
                <th>Bank Name</th>
                <th>Bank Branch</th>
                <th>Bank Account Type</th>
                <th>Bank Account Name</th>
                <th>Bank Account Number</th>
                <th>Bank Routing Number</th>
                <th>bKash</th>
                <th>Nagad</th>
                <th>Rocket</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{serviceHistorys.paymetdetail[0]?.bank_name}</td>
                <td>{serviceHistorys.paymetdetail[0]?.bank_branch}</td>
                <td>{serviceHistorys.paymetdetail[0]?.bank_account_type}</td>
                <td>{serviceHistorys.paymetdetail[0]?.bank_account_name}</td>
                <td>{serviceHistorys.paymetdetail[0]?.account_number}</td>
                <td>{serviceHistorys.paymetdetail[0]?.bank_routing_number}</td>
                <td>{serviceHistorys.paymetdetail[0]?.bkash_number}</td>
                <td>{serviceHistorys.paymetdetail[0]?.nagad_number}</td>
                <td>{serviceHistorys.paymetdetail[0]?.rocket_number}</td>

              </tr>

            </tbody>
          </Table>
        </div>
      }

      {page == "Pending" &&

        <div style={{ margin: 20 }}>
          <h1 style={{ textAlign: "center", marginTop: -40, }}>Pending Services ({serviceHistorys.pending_service.length})</h1>
          {serviceHistorys.pending_service.length > 0 &&
            <Table responsive>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Contact Person</th>
                  <th>Mobile</th>
                  <th>Address</th>
                  <th>Service</th>
                  <th>Service Type</th>
                  <th>Area</th>
                  <th>Unit Price</th>
                  <th>Qty.</th>
                  <th>Total Price</th>
                  <th>Payment</th>
                  <th>Expected At</th>


                </tr>
              </thead>
              <tbody>
                {serviceHistorys.pending_service.map((item) => (
                  <tr>
                    <td>{item.mainorderid}</td>
                    <td >{item.contact_person}</td>
                    <td>{item.contact_person_number}</td>
                    <td>{item.order_address}</td>
                    <td>{item.service}</td>
                    <td >{item.service_name}</td>
                    <td>{item.area > 0 ? item.area + " sqft." : "-"}</td>
                    <td>{`৳`} {item.price}</td>
                    <td>{item.quantity}</td>
                    <td>{`৳`} {parseInt(item.price * item.quantity)}</td>
                    <td>{item.isDigital == "false" ? "Cash" : "Digital"}</td>
                    <td>{item.expectedTime}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }

        </div>

      }

      {page == "Completed" &&

        <div style={{ margin: 20 }}>

          <h1 style={{ textAlign: "center", marginTop: -40, }}>Completed Services ({serviceHistorys.given_service.length})</h1>
          {serviceHistorys.given_service.length > 0 &&
            <Table responsive>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Status</th>
                  <th>Contact Person</th>
                  <th>Mobile</th>
                  <th>Address</th>
                  <th>Service</th>
                  <th>Service Type</th>
                  <th>Area</th>
                  <th>Unit Price</th>
                  <th>Qty.</th>
                  <th>Total Price</th>
                  <th>Payment</th>
                  <th>Expected At</th>


                </tr>
              </thead>
              <tbody>
                {serviceHistorys.given_service.map((item) => (
                  <tr>
                    <td>{item.mainorderid}</td>
                    <td>{item.status === 8 ? "Redeemed" : "Not Redeemed"}</td>
                    <td >{item.contact_person}</td>
                    <td>{item.contact_person_number}</td>
                    <td>{item.order_address}</td>
                    <td>{item.service}</td>
                    <td >{item.service_name}</td>
                    <td>{item.area > 0 ? item.area + " sqft." : "-"}</td>
                    <td>{`৳`} {item.price}</td>
                    <td>{item.quantity}</td>
                    <td>{`৳`} {parseInt(item.price * item.quantity)}</td>
                    <td>{item.isDigital == "false" ? "Cash" : "Digital"}</td>
                    <td>{item.expectedTime}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        </div>
      }

      {page == "Cancelled" &&

        <div style={{ margin: 20 }}>

          <h1 style={{ textAlign: "center", marginTop: -40, }}>Cancelled Services ({serviceHistorys.canceled_service.length})</h1>
          {serviceHistorys.canceled_service.length > 0 &&
            <Table responsive>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Contact Person</th>
                  <th>Mobile</th>
                  <th>Address</th>
                  <th>Service</th>
                  <th>Service Type</th>
                  <th>Area</th>
                  <th>Unit Price</th>
                  <th>Qty.</th>
                  <th>Total Price</th>
                  <th>Payment</th>
                  <th>Expected At</th>


                </tr>
              </thead>
              <tbody>
                {serviceHistorys.canceled_service.map((item) => (
                  <tr>
                    <td>{item.mainorderid}</td>
                    <td >{item.contact_person}</td>
                    <td>{item.contact_person_number}</td>
                    <td>{item.order_address}</td>
                    <td>{item.service}</td>
                    <td >{item.service_name}</td>
                    <td>{item.area > 0 ? item.area + " sqft." : "-"}</td>
                    <td>{`৳`} {item.price}</td>
                    <td>{item.quantity}</td>
                    <td>{`৳`} {parseInt(item.price * item.quantity)}</td>
                    <td>{item.isDigital == "false" ? "Cash" : "Digital"}</td>
                    <td>{item.expectedTime}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        </div>
      }

{page == "Services" &&

        <div style={{ margin: 20 }}>
          <h1 style={{ textAlign: "center", marginTop: -40, }}>Offering {vendorServices.length} Services</h1>
          {vendorServices.length > 0 &&
            <Table responsive>
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Sub Service</th>
                  <th>Unit Price</th>
                  <th>Zones</th>
                </tr>
              </thead>
              <tbody>
                {vendorServices.map((item) => (
                  <tr>
                    
                    <td>{item.service}</td>
                    <td >{item.service_name}</td>
                    <td>৳ {item.price}</td>
                    <td >{item.zone}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }

        </div>

      }

      {page == "Deposit" &&

        <Row style={{ margin: 20, }}>
          <Col></Col>
          <Col>
          <Row>
          
            <h2 style={{ textAlign: "left",margin:0,padding:0, marginBottom:10}}>Current Deposit {`৳`} {serviceHistorys.paymetdetail[0]?.deposit}</h2>
            
              <p style={{margin:0,padding:0}}>
                Enter Security Deposit amount you received from {serviceHistorys.primaryinfo[0].vendor_name}
              </p>
              
              <input
                className="form-control"
                type="text"
                name=""
                id=""
                value={deposit}
                onChange={(event) => setDeposit(event.target.value)}
                placeholder="৳"
                required
              />

            <Button onClick={() => submitDeposit()}>
              Submit
            </Button>
            </Row>
          </Col>
          <Col>
          
              </Col>
        </Row>
      }




    </div >


  );
};

export default RegVendorDetails;
