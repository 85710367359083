import React from 'react';
import * as FaIcons from 'react-icons/fa';

export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <FaIcons.FaTachometerAlt />,
    cName: 'nav-text'
  },
  {
    title: 'Orders',
    path: '/orders/Pending',
    icon: <FaIcons.FaCartArrowDown />,
    cName: 'nav-text'
  },
  {
    title: 'Quotations',
    path: '/quotation',
    icon: <FaIcons.FaEnvelopeOpenText />,

    cName: 'nav-text'
  },
  {
    title: 'Coupons',
    path: '/coupon',
    icon: <FaIcons.FaTag />,

    cName: 'nav-text'
  },
  {
    title: 'Users',
    path: '/users',
    icon: <FaIcons.FaUsers />,
    cName: 'nav-text'
  },
  {
    title: 'Vendors',
    path: '/vendors',
    icon: <FaIcons.FaUserTie />,
    cName: 'nav-text'
  },
  
  {
    title: 'Services',
    path: '/services',
    icon: <FaIcons.FaList />,
    cName: 'nav-text'
  },
  
  {
    title: 'Locations',
    path: '/locations',
    icon: <FaIcons.FaMapMarker />,
    cName: 'nav-text'
  },
  {
    title: 'Wallet',
    path: '/wallet',
    icon: <FaIcons.FaWallet />,
    cName: 'nav-text'
  },
  
];
