import React, { useEffect, useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import { useHistory } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import

import { Link } from "react-router-dom";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import "./PendingOrder.css"

function AdminPendingOrderDetails({ match }) {
  const [serviceHistorys, SetServiceHistorys] = useState(
    [
      {
        id: "",
        vendor_id: "",
        user_id: "",
        order_id: "",
        created_at: "",
        updated_at: null,
        zone_id: "",
        service_id: "",
        name: "",
        service_name: "",
        service: "",
        zone: ""
      }
    ]
  );
  const [vendors, setVendors] = useState([]);
  const [clicked, setClicked] = useState("");
  const [pendingId, setPendingId] = useState("");
  const [selectedVendorId, setSelectedVendorId] = useState(null);

  const [modalShow, setModalShow] = useState(false);

  const [vendorEmpty, setVendorEmpty] = useState(false);


  const [loading, setLoading] = useState(true);
  const [loadingVendor, setLoadingVendor] = useState(false);


  useEffect(() => {
    fetchItem();

  }, []);
  const history = useHistory();

  const modalHide = () => {
    setLoading(true)
    fetchItem();
    setModalShow(false)
  }

  const fetchEligibleVendors = async (pendingId, serviceID, zoneId) => {
    setPendingId(pendingId);
    setVendorEmpty(false);
    setClicked(pendingId);
    setLoadingVendor(true)

    const link = "https://backend.amaderservice.com/api/eligibleVendor?serviceid=" + serviceID + "&zoneid=" + zoneId;
    const data = await fetch(link);
    const item = await data.json();
    setVendors(item);
    setLoadingVendor(false);
    if (item.length == 0) {
      setVendorEmpty(true);

    }


  }

  const assignVendor = async (vendorId) => {
    setSelectedVendorId(vendorId)
    confirmAlert({
      title: 'Confirm',
      message: 'Are you sure you want to assign this Vendor?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const link =
            "https://backend.amaderservice.com/api/placeOrdertoVendor?id=" + pendingId + "&vendorid=" + vendorId;
            const data = await fetch(link);
            const item = await data.json();
            if (item == 1) {
              setModalShow(true)
            } else {
            alert("fail")      
          }              
          }
        },
        {
          label: 'No',
          onClick: () => console.log('Click No')
        }
      ]
    }); 





    

    //viewCart();
  };

  const fetchItem = async () => {
    setVendors([]);
    const link = "https://backend.amaderservice.com/api/pendingServices?orderid=" + match.params.id;

    const data = await fetch(link);
    const item = await data.json();
    if (item.length == 0) {
      setLoading(true)
      history.push("/orders/Pending");
    } else {
      setLoading(false)
    }
    SetServiceHistorys(item);
    setLoading(false);



  };

  return (
    <div>
      <Modal
        size="sm"
        show={modalShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h2 className="text-success">

            Successful!
          </h2>
          <h5>

            Assigned Vendor to the Service!
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => modalHide()}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>


      {loading ?
        <div style={{ margin: 20 }}>
          <h2 style={{ fontSize: "14px", color: "#387cd5" }}>Loading</h2>
        </div>
        :

        <div style={{ margin: 20 }}>
          <h1 onClick={() => history.push("/orders/Pending")} style={{ cursor: "pointer" }}>&#x2190;</h1>

          <h3>Pending Order Details</h3>
          <Table responsive>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>User ID</th>
                <th>Username</th>
                <th>Contact Person</th>
                <th>Contact Person Mobile</th>
                <th>Order Address</th>
                <th>Expected At</th>


              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{serviceHistorys[0].order_id}</td>
                <td >{serviceHistorys[0].user_id}</td>
                <td>{serviceHistorys[0].name}</td>
                <td>{serviceHistorys[0].contact_person}</td>
                <td>{serviceHistorys[0].contact_person_number}</td>
                <td>{serviceHistorys[0].order_address}</td>
                <td >{serviceHistorys[0].expectedTime}</td>
              </tr>

            </tbody>
          </Table>



          <h3 style={{ marginTop: 30, textAlign:"center" }}>Select Vendors</h3>

          <Container>

            <Row style={{ backgroundColor: "", padding: 10, borderRadius: 10, width:"60%", margin:"auto" }}>
              {/*left column */}
              <Col>


                <h4>
                  Services in Order #{serviceHistorys[0].order_id} <br />{" "}
                </h4>
                {serviceHistorys.map((item) => (
                  <div onClick={() => fetchEligibleVendors(item.id, item.service_id, item.zone_id)} 
                  style={{ border: "1px solid #2a345750", cursor: "pointer",backgroundColor:clicked == item.id ? "#01535e" : "white", color: clicked == item.id ? "white" : "black", borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5, paddingLeft:10 }}>
                  <p style={{ margin: 0, padding: 0 }}>{item.service} ({item.service_name})</p>
                  <p style={{ margin: 0, padding: 0, color:"grey" }}>
                  {`৳`} {item.price * item.quantity} (Qty. {item.quantity}) {item.area > 0 ? " Area " + item.area + " sqft." : ""}
                  </p>
                </div>


                  
                ))}


              </Col>
              {/*right column */}

              <Col>



                {loadingVendor ?
                  <Row>
                    <h4>
                      Loading...
                    </h4>
                  </Row>
                  :


                  <Row>
                    {vendorEmpty ?
                      <h3>No Vendor available</h3>
                      :
                      <div>
                        <h4>
                          {vendors.length > 0 ? "Eligible Vendors" : "Choose a Service"}
                        </h4>
                        {vendors.map((item) => (
                          <div onClick={() => assignVendor(item.id)} 
                            style={{ border: "1px solid #2a345750", cursor: "pointer",backgroundColor:selectedVendorId == item.id ? "#2a3457" : "white", color: selectedVendorId == item.id ? "white" : "black", borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5, paddingLeft:10 }}>
                            <p style={{ margin: 0, padding: 0 }}>{item.vendor_name} </p>
                            <p style={{ margin: 0, padding: 0, color:"grey" }}>
                              {item.address}
                            </p>
                          </div>

                        ))}
                      </div>
                    }
                  </Row>
                }
              </Col>
            </Row>
          </Container>

        </div>
      }
    </div>
  );
};

export default AdminPendingOrderDetails;
