import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import "./PendingOrder.css"
import vendorDashDataFormat from './data';
import axios, { post } from 'axios';
function RegVendorDetails({ match }) {
  const [cart, setCart] = useState([]);
  const [requested, setRequested] = useState([]);
  const [requestedHEAD, setRequestedHEAD] = useState([]);
  const [historyData, setHistory] = useState([]);
  const [historyHEAD, setHistoryHEAD] = useState([]);
  const [serviceHistorys, SetServiceHistorys] = useState(vendorDashDataFormat);

  const [page, setPage] = useState("Pending");


  useEffect(() => {
    fetchCart();
    fetchRequested();
    fetchHistory();


  }, []);
  const history = useHistory();

  const fetchCart = async () => {
    fetch("https://backend.amaderservice.com/api/ViewCart?userid=" + match.params.id)
      .then((response) => response.json())
      .then((data) => setCart(data))
  }

  const fetchRequested = async () => {
    fetch("https://backend.amaderservice.com/api/userordersincompleted?userid=" + match.params.id)
      .then((response) => response.json())
      .then((data) => {
        setRequested(data)
        setRequestedHEAD(Object.keys(data))

      })
  }

  const fetchHistory = async () => {
    fetch("https://backend.amaderservice.com/api/userorderscompleted?userid=" + match.params.id)
      .then((response) => response.json())
      .then((data) => {
        setHistory(data)
        setHistoryHEAD(Object.keys(data))

      })
  }

  return (
    <div>
      <Row style={{ width: "100%", height: 50, backgroundColor: "#060b26", margin: 0, }}>
        
        <Col onClick={() => setPage("Pending")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Pending" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Pending</h4>
        </Col>
        <Col onClick={() => setPage("Completed")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Completed" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>History</h4>
        </Col>
        <Col onClick={() => setPage("Cart")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Cart" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Cart</h4>
        </Col>
        
      </Row>
      <h1 onClick={() => history.push("/users")} style={{ cursor: "pointer", margin: 0, marginLeft: 20 }}>&#x2190;</h1>
      

      {page == "Pending" &&

        <div style={{ margin: 20 }}>
          <h1 style={{ textAlign: "center", marginTop: -40, }}>Pending Orders ({requestedHEAD.length})</h1>
          {requestedHEAD.length > 0 && 
            <div>
              {requestedHEAD.map((item) => (

                <Col style={{ backgroundColor: "", padding: 20, borderRadius: 20 }}>

                  <Row style={{ textAlign: "center", }}>
                    <p style={{ margin: 0, padding: 0, fontSize: 25, fontWeight: "bold" }}>#{item}</p>
                  </Row>
                  <Row style={{ textAlign: "center" }}>
                    <p style={{ margin: 0, padding: 0 }}>Expected at: {requested[item][0].expectedTime}</p>
                  </Row>
                  <Row style={{ textAlign: "center" }}>
                    <p style={{ margin: 0, padding: 0 }}>{requested[item][0].contact_person} ({requested[item][0].contact_person_number}) ({requested[item][0].order_address})</p>
                  </Row>

                  <Row>
                    <div style={{ backgroundColor: "black", height: 1, width: "100%", marginTop: 10, marginBottom: 0 }} />
                    <Row>
                      <Col>
                        <p style={{ margin: 0, padding: 0 }}>Service</p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0 }}>Sub Service</p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0 }}>Area</p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0 }}>Unit Price</p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0 }}>Qty.</p>
                      </Col>
                      <Col style={{ textAlign: "right" }}>
                        <p style={{ margin: 0, padding: 0 }}>Total Price</p>
                      </Col>


                    </Row>
                    <div style={{ backgroundColor: "black", height: 1, width: "100%", marginTop: 0, marginBottom: 10 }} />

                    {requested[item].map((item) => (

                      <Row>
                        <Col>
                          <p style={{ margin: 0, padding: 0 }}>{item.service}</p>
                        </Col>
                        <Col>
                          <p style={{ margin: 0, padding: 0 }}>{item.service_name}</p>
                        </Col>
                        <Col>
                          <p style={{ margin: 0, padding: 0 }}>{item.area > 0 ? item.area + " sqft." : "N/A"}</p>
                        </Col>
                        <Col>
                          <p style={{ margin: 0, padding: 0 }}>৳ {item.area > 0 ? item.price/item.area : item.price}</p>
                        </Col>
                        <Col>
                          <p style={{ margin: 0, padding: 0 }}>{item.quantity}</p>
                        </Col>
                        <Col style={{ textAlign: "right" }}>
                          <p style={{ margin: 0, padding: 0 }}>৳ {item.price * item.quantity}</p>
                        </Col>


                      </Row>

                    ))}
                    <div style={{ backgroundColor: "black", height: 1, width: "100%", marginTop: 10, marginBottom: 0 }} />

                    <Row>
                      <Col>
                      </Col>
                      <Col>
                      </Col>
                      <Col>
                      </Col>
                      <Col>
                      </Col>
                      <Col>
                      </Col>
                      <Col style={{ textAlign: "right" }}>
                        <p style={{ margin: 0, padding: 0 }}>Total ৳ { requested[item].reduce(function (prev, cur){
                              return parseInt(prev) + parseInt((cur.price * cur.quantity));
                            }, 0)
                          }</p>
                      </Col>


                    </Row>

                    <Row>
                      <Col>
                      </Col>
                      <Col>
                      </Col>
                      <Col>
                      </Col>
                      <Col>
                      </Col>
                      <Col>
                      </Col>
                      <Col style={{ textAlign: "right" }}>
                        <p style={{ margin: 0, padding: 0 }}>Coupon Discount - ৳ {requested[item][0].coupon_discount_amount}</p>
                      </Col>


                    </Row>
                    <Row>
                      <Col>
                      </Col>
                      <Col>
                      </Col>
                      <Col>
                      </Col>
                      <Col>
                      </Col>
                      <Col>
                      </Col>
                      <Col style={{ textAlign: "right" }}>
                        <p style={{ margin: 0, padding: 0,fontWeight:"bold" }}>Final Amount ৳ {" "} 
                           { requested[item].reduce(function (prev, cur){
                              return parseInt(prev) + parseInt((cur.price * cur.quantity));
                            }, 0) - requested[item][0].coupon_discount_amount
                          }
                        </p>
                      </Col>


                    </Row>
                    <div style={{ backgroundColor: "black", height: 1, width: "100%", marginTop: 5, marginBottom: 10 }} />
                  </Row>

                </Col>


              ))}
            </div>

          }

        </div>

      }

{page == "Completed" &&

<div style={{ margin: 20 }}>
  <h1 style={{ textAlign: "center", marginTop: -40, }}>Order History ({historyHEAD.length})</h1>
  {historyHEAD.length > 0 &&
    <div>
      {historyHEAD.map((item) => (

        <Col style={{ backgroundColor: "", padding: 20, borderRadius: 20 }}>

          <Row style={{ textAlign: "center", }}>
            <p style={{ margin: 0, padding: 0, fontSize: 25, fontWeight: "bold" }}>#{item}</p>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <p style={{ margin: 0, padding: 0 }}>Completed on: {historyData[item][0].expectedTime}</p>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <p style={{ margin: 0, padding: 0 }}>{historyData[item][0].contact_person} ({historyData[item][0].contact_person_number}) ({historyData[item][0].order_address})</p>
          </Row>

          <Row>
            <div style={{ backgroundColor: "black", height: 1, width: "100%", marginTop: 10, marginBottom: 0 }} />
            <Row>
              <Col>
                <p style={{ margin: 0, padding: 0 }}>Service</p>
              </Col>
              <Col>
                <p style={{ margin: 0, padding: 0 }}>Sub Service</p>
              </Col>
              <Col>
                <p style={{ margin: 0, padding: 0 }}>Area</p>
              </Col>
              <Col>
                <p style={{ margin: 0, padding: 0 }}>Unit Price</p>
              </Col>
              <Col>
                <p style={{ margin: 0, padding: 0 }}>Qty.</p>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <p style={{ margin: 0, padding: 0 }}>Total Price</p>
              </Col>


            </Row>
            <div style={{ backgroundColor: "black", height: 1, width: "100%", marginTop: 0, marginBottom: 10 }} />

            {historyData[item].map((item) => (

              <Row>
                <Col>
                  <p style={{ margin: 0, padding: 0 }}>{item.service}</p>
                </Col>
                <Col>
                  <p style={{ margin: 0, padding: 0 }}>{item.service_name}</p>
                </Col>
                <Col>
                  <p style={{ margin: 0, padding: 0 }}>{item.area > 0 ? item.area + " sqft." : "N/A"}</p>
                </Col>
                <Col>
                <p style={{ margin: 0, padding: 0 }}>৳ {item.area > 0 ? item.price/item.area : item.price}</p>
                </Col>
                <Col>
                  <p style={{ margin: 0, padding: 0 }}>{item.quantity}</p>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <p style={{ margin: 0, padding: 0 }}>৳ {item.price * item.quantity}</p>
                </Col>


              </Row>

            ))}
            <div style={{ backgroundColor: "black", height: 1, width: "100%", marginTop: 10, marginBottom: 0 }} />

            <Row>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <p style={{ margin: 0, padding: 0 }}>Total ৳ { historyData[item].reduce(function (prev, cur){
                      return parseInt(prev) + parseInt((cur.price * cur.quantity));
                    }, 0)
                  }</p>
              </Col>


            </Row>

            <Row>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <p style={{ margin: 0, padding: 0 }}>Coupon Discount - ৳ {historyData[item][0].coupon_discount_amount}</p>
              </Col>


            </Row>
            <Row>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <p style={{ margin: 0, padding: 0,fontWeight:"bold" }}>Final Amount ৳ {" "} 
                   { historyData[item].reduce(function (prev, cur){
                      return parseInt(prev) + parseInt((cur.price * cur.quantity));
                    }, 0) - historyData[item][0].coupon_discount_amount
                  }
                </p>
              </Col>


            </Row>
            <div style={{ backgroundColor: "black", height: 1, width: "100%", marginTop: 5, marginBottom: 10 }} />
          </Row>

        </Col>


      ))}
    </div>

  }

</div>

}

      

      {page == "Cart" &&

        <div style={{ margin: 20 }}>
          <h1 style={{ textAlign: "center", marginTop: -40, }}>{cart.length} Services in Cart</h1>
          {cart.length > 0 &&
            <Table responsive>
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Sub Service</th>
                  <th>Unit Price</th>
                  <th>Qty.</th>
                  <th>Area</th>

                </tr>
              </thead>
              <tbody>
                {cart.map((item) => (
                  <tr>

                    <td>{item.service}</td>
                    <td >{item.service_name}</td>
                    <td>৳ {item.price}</td>
                    <td>{item.quantity}</td>
                    <td>{item.area > 0 ? item.area : "-"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }

        </div>

      }




    </div >


  );
};

export default RegVendorDetails;
