import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./PendingOrder.css"
import { Table, Row, Col, Modal, Button } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import "./ServicesAdd.css";
const AdminPendingOrder = () => {

  const [modalShow, setModalShow] = useState(false);
  const [subServiceModalShow, setSubServiceModalShow] = useState(false);

  const [subServiceModalName, setSubServiceModalName] = useState("");
  const [subServiceModalPrice, setSubServiceModalPrice] = useState("");
  const [subServiceModalType, setSubServiceModalType] = useState("");

  const [modalType, setModalType] = useState();
  const [modalInput, setModalInput] = useState("");

  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);
  const [fourth, setFourth] = useState([]);

  const [firstId, setFirstId] = useState(null);
  const [secondId, setSecondId] = useState(null);
  const [thirdId, setThirdId] = useState(null);
  const [fourthId, setFourthId] = useState(null);





  useEffect(() => {
    fetch_First()
  }, []);
  const history = useHistory();


  const fetch_First = async () => {
    fetch("https://backend.amaderservice.com/api/showservice")
      .then((response) => response.json())
      .then((data) => setFirst(data.reverse()))
  };

  const fetch_Second = async (id) => {

    setFirstId(id)
    fetch("https://backend.amaderservice.com/api/subcatagory?maincatagoryid=" + id)
      .then((response) => response.json())
      .then((data) => setSecond(data.reverse()))
  };

  const fetch_Third = async (id) => {
    setSecondId(id)
    fetch("https://backend.amaderservice.com/api/servicelist?subcatagoryid=" + id)
      .then((response) => response.json())
      .then((data) => setThird(data.reverse()))
  };

  const fetch_Fourth = async (id) => {
    setThirdId(id)
    fetch("https://backend.amaderservice.com/api/finalServiceList?serviceid=" + id)
      .then((response) => response.json())
      .then((data) => setFourth(data.reverse()))
  };

  function openFirstModal() {
    setModalType("Enter Category Name");
    setModalShow(true);
  }

  function openSecondModal() {
    setModalType("Enter Sub Category Name");
    setModalShow(true);
  }

  function openThirdModal() {
    setModalType("Enter Service Name");
    setModalShow(true);
  }

  function openFourthModal() {
    setModalType("Enter Sub Service Name, Price & Type");
    setSubServiceModalShow(true);
  }

  const submit = async () => {
    if (modalType == "Enter Category Name") {
      createFirst();
    } else if (modalType == "Enter Sub Category Name") {
      createSecond();
    } else if (modalType == "Enter Service Name") {
      createThird();
    } else {
      alert("Failed!")
    }
  }



  const createFirst = async () => {
    const optionsCatagory = await fetch("https://backend.amaderservice.com/api/addMainCatagory?catagoryName=" + modalInput);
    fetch_First();
    setModalShow(false);
    setSecond([]);
    setThird([]);
    setFourth([]);
    setModalInput("")

  };

  const createSecond = async () => {
    const link = "https://backend.amaderservice.com/api/addServiceType?servicetypename=" + modalInput + "&maincatagoryid=" + firstId;
    const optionsCatagory = await fetch(link);
    fetch_Second(firstId);
    setModalShow(false);

    setThird([]);
    setFourth([]);
    setModalInput("")

  };

  const createThird = async () => {

    const link = "https://backend.amaderservice.com/api/addService?servicename=" + modalInput + "&servicetypeid=" + secondId;

    const res = await fetch(link);
    const responseJson = await res.json();
    if (responseJson.status == "success") {
      fetch_Third(secondId);
    }
    setModalShow(false);

    setFourth([]);
    setModalInput("")

  };

  const createFourth = async () => {
    const link = "https://backend.amaderservice.com/api/addFinalService?servicename="
      + subServiceModalName
      + "&servicesid="
      + thirdId
      + "&price="
      + subServiceModalPrice
      + "&typecode="
      + subServiceModalType;
    fetch(link)
      .then((res) => res.json())
      .then((result) => { console.log(result); });
    fetch_Fourth(thirdId);
    setSubServiceModalShow(false);
    setSubServiceModalName("")
    setSubServiceModalPrice("")
    setSubServiceModalType("")




  };


  return (
    <div>
      <Row style={{ width: "100%", height: 50, backgroundColor: "#060b26", margin: 0, }}>
        <Col onClick={() => history.push('/services')} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>All Services</h4>
        </Col>
        <Col style={{ cursor: "pointer", textAlign: "center", backgroundColor: "#ffffff20" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Add Service</h4>
        </Col>
        <Col onClick={() => history.push("/subCategoryEdit")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Edit Sub Category</h4>
        </Col>
        <Col  onClick={() => history.push("/servicesEdit")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Edit Service</h4>
        </Col>
        <Col onClick={() => history.push("/servicesDelete")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Delete Service</h4>
        </Col>
      </Row>     


      <div style={{ marginBottom: 100 }}>
        <h1 style={{ textAlign: "center", margin: 20, }}>Add Service</h1>

        <Modal
          size="sm"
          show={modalShow}
          onHide={() => setModalShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h5>

              {modalType}
            </h5>
            <input
              type="text"
              class="form-control"
              id=""
              style={{ marginBottom: 0, marginTop: 10 }}
              placeholder="Enter here..."
              value={modalInput}
              onChange={(event) => setModalInput(event.target.value)}
            ></input>


          </Modal.Body>
          <Modal.Footer>
            <Button style={{ width: "100%", marginTop: 0 }} onClick={() => submit()}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="md"
          show={subServiceModalShow}
          onHide={() => setSubServiceModalShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h5>Enter Sub Service Name, Price and Type
            </h5>
            <p style={{ padding: 0, margin: 0, marginTop: 10 }}>Sub Service Name</p>
            <input
              type="text"
              class="form-control"
              id=""
              style={{ marginBottom: 0, marginTop: 0 }}
              placeholder="Enter here..."
              value={subServiceModalName}
              onChange={(event) => setSubServiceModalName(event.target.value)}
            ></input>

            <p style={{ padding: 0, margin: 0, marginTop: 10 }}>Sub Service Price</p>
            <input
              type="text"
              class="form-control"
              id=""
              style={{ marginBottom: 0, marginTop: 0 }}
              placeholder="৳"
              value={subServiceModalPrice}
              onChange={(event) => setSubServiceModalPrice(event.target.value)}
            ></input>
            <p style={{ padding: 0, margin: 0, marginTop: 10 }}>Sub Service Type</p>

            <div onClick={() => {
              setSubServiceModalType("General");
            }}
              style={{
                border: "2px solid #3b430150", cursor: "pointer",
                backgroundColor: subServiceModalType == "General" ? "#3b4301" : "", color: subServiceModalType == "General" ? "white" : "black",
                marginBottom: 20, marginTop: 10, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5
              }}>
              <p style={{ margin: 0, padding: 0 }} >General</p>

            </div>

            <div onClick={() => {
              setSubServiceModalType("PerUnit");
            }}
              style={{
                border: "2px solid #3b430150", cursor: "pointer",
                backgroundColor: subServiceModalType == "PerUnit" ? "#3b4301" : "", color: subServiceModalType == "PerUnit" ? "white" : "black",
                marginBottom: 20, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5
              }}>
              <p style={{ margin: 0, padding: 0 }} >Per Unit (For Services involving Floor Area)</p>

            </div>

            <div onClick={() => {
              setSubServiceModalType("Quotation");
            }}
              style={{
                border: "2px solid #3b430150", cursor: "pointer",
                backgroundColor: subServiceModalType == "Quotation" ? "#3b4301" : "", color: subServiceModalType == "Quotation" ? "white" : "black",
                marginBottom: 20, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5
              }}>
              <p style={{ margin: 0, padding: 0 }} >Quotation</p>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{ width: "100%", marginTop: 0 }} onClick={() => createFourth()}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>




        <Row style={{ textAlign: "center"}}>
          <Col className="sAddheadingCol">
            <div className="sAddHeadingText">
              <p className="sAddHeadingTextP">Category ({first.length})</p>
            </div>
            <div 
            onClick={() => openFirstModal()} className="sAddMoreButton">
              <p style={{margin: 0, padding: 0, color:"#01535e"}}>Add Category...</p>
            </div>
          </Col>
          <Col className="sAddheadingCol">
            <div className="sAddHeadingText">
              <p className="sAddHeadingTextP">Sub Category ({second.length})</p>
            </div>
            <div 
            onClick={() => firstId === null ? alert("Please select a Category first!") : openSecondModal()} className="sAddMoreButton">
              <p style={{margin: 0, padding: 0, color:"#2a3457"}}>Add Sub Category...</p>
            </div>
          </Col>
          <Col className="sAddheadingCol">
            <div  className="sAddHeadingText">
              <p className="sAddHeadingTextP">Service ({third.length})</p>
            </div>
            <div 
            onClick={() => secondId === null ? alert("Please select a Sub Category first!") : openThirdModal()}  className="sAddMoreButton">
              <p style={{margin: 0, padding: 0,color:"#761e6e" }}>Add Service...</p>
            </div>
          </Col>
          <Col className="sAddheadingCol">
            <div  className="sAddHeadingText">
              <p className="sAddHeadingTextP">Sub Service ({fourth.length})</p>
            </div>
            <div 
            onClick={() => thirdId === null ? alert("Please select a Service first!") : openFourthModal()} className="sAddMoreButton">
              <p style={{margin: 0, padding: 0, color:"#3b4301"}}>Add Sub Service...</p>
            </div>
          </Col>
        </Row>




        <Row>
          <Col style={{ textAlign: "center", }}>
            {first.map((item) => (
              <div onClick={() => {
                fetch_Second(item.id)
                setFirstId(item.id)
                setThird([])
                setFourth([])

              }} style={{ border: "1px solid #01535e50", cursor: "pointer", backgroundColor: item.id == firstId ? "#01535e" : "", color: item.id == firstId ? "white" : "black", marginLeft: 20, marginRight:20, marginBottom:0, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
                <p style={{ margin: 0, padding: 0 }} >{item.category_name} </p>

              </div>
            ))}
          </Col>

          <Col style={{ textAlign: "center" }}>
            {second.map((item) => (
              <div onClick={() => fetch_Third(item.id)} style={{ border: "1px solid #2a345750", cursor: "pointer", backgroundColor: item.id == secondId ? "#2a3457" : "", color: item.id == secondId ? "white" : "black", marginLeft: 20, marginRight:20, marginBottom:0, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
                <p style={{ margin: 0, padding: 0 }}>{item.service_type_name} </p>

              </div>
            ))}
          </Col>

          <Col style={{ textAlign: "center" }}>
            {third.map((item) => (
              <div onClick={() => fetch_Fourth(item.id)} style={{ border: "1px solid #761e6e50", cursor: "pointer", backgroundColor: item.id == thirdId ? "#761e6e" : "", color: item.id == thirdId ? "white" : "black", marginLeft: 20, marginRight:20, marginBottom:0, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
                <p style={{ margin: 0, padding: 0 }}>{item.service_name} </p>

              </div>
            ))}
          </Col>

          <Col style={{ textAlign: "center" }}>
            {fourth.map((item) => (
              <div style={{ border: "1px solid #3b430150", cursor: "pointer", backgroundColor: item.id == fourthId ? "#3b4301" : "", color: item.id == fourthId ? "white" : "black", marginLeft: 20, marginRight:20, marginBottom:0, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
                <p style={{ margin: 0, padding: 0 }}>{item.service_name} </p>
                <p style={{ margin: 0, padding: 0 }}>৳ {item.price} </p>
                <p style={{ margin: 0, padding: 0 }}>{item.typecode} </p>

              </div>
            ))}
          </Col>

        </Row>
      </div>


    </div>
  );
};

export default AdminPendingOrder;
