import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./PendingOrder.css"
import { Table, Row, Col } from 'react-bootstrap';
const RegUsers = () => {
  const [serviceHistorys, SetServiceHistorys] = useState([]);
  const [query, setQuery] = useState("");

  const [page, setPage] = useState({
    currentPage: null,
    lastPage: null
  });
  useEffect(() => {
    fetchItem(1);
  }, []);
  const history = useHistory();



  const fetchItem = async (page) => {
    fetch("https://backend.amaderservice.com/api/vendorlist?page=" + page)
      .then((response) => response.json())
      .then((data) => {
        SetServiceHistorys(data.data.reverse())
        let obj = {
          currentPage: page,
          lastPage: data.last_page
        }
        setPage(obj)
      })
  };

  function searchHandler() {
    const link = "https://backend.amaderservice.com/api/"
    fetch(link + "searchVendorList?search=" + query)
      .then((response) => response.json())
      .then((data) => {
        SetServiceHistorys(data.data.reverse())
        let obj = {
          currentPage: 1,
          lastPage: data.last_page
        }
        setPage(obj)
      })

  }


  function handleRowClick(id) {
    history.push(`/vendorDetails/${id}`);
  }
  return (
    <div>
      <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }}>
        {/* SEARCH AND PAGINATION START */}
        <Row style={{ width: "100%", margin: 0, padding: 0 }}>
          <Col style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>
            <h4 style={{ padding: 0, color: "#060b26", textAlign: "left", }}>Registered Vendors</h4>

          </Col>

          <Col style={{ cursor: "pointer", textAlign: "right", padding: 0, marginTop: -5 }}>
            <form onSubmit={() => searchHandler()}>
              <input
                type="text"
                class="form-control"
                id=""
                style={{ margin: 0 }}
                placeholder="Search..."
                value={query}
                onChange={(event) => setQuery(event.target.value)}
              ></input>
            </form>

          </Col>

          <Col style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>
            <Row style={{ width: "100%", margin: 0, padding: 0 }}>

              <Col onClick={() => {
                let obj = {
                  currentPage: page.currentPage - 1,
                  lastPage: page.lastPage
                }

                if (page.currentPage !== 1) {

                  setPage(obj)
                  fetchItem(page.currentPage - 1)

                }
              }} style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>

                <h4 className="paginationButton" style={{ padding: 0, textAlign: "right", }}>&#9664;</h4>
              </Col>

              <Col style={{ cursor: "pointer", textAlign: "center", padding: 0 }}>

                <h4 style={{ padding: 0, textAlign: "center", color: "#060b26" }}>Page {page.currentPage} of {page.lastPage}</h4>
              </Col>

              <Col onClick={() => {
                let obj = {
                  currentPage: page.currentPage + 1,
                  lastPage: page.lastPage
                }
                if (page.currentPage !== page.lastPage) {

                  setPage(obj)
                  fetchItem(page.currentPage + 1)

                }
              }}
                style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>

                <h4
                  className="paginationButton" style={{ padding: 0, textAlign: "left" }}>&#9654;</h4>
              </Col>

            </Row>
          </Col>


        </Row>
        {/* SEARCH AND PAGINATION END */}

        <Table responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Vendor Name</th>
              <th>Mobile</th>
              <th>Email</th>
              <th>Address</th>
              <th>Trade License</th>
            </tr>
          </thead>
          <tbody>
            {serviceHistorys.map((item) => (
              <tr onClick={() => handleRowClick(item.id)}>
                <td >{item.id}</td>
                <td >{item.vendor_name}</td>
                <td>{item.contact}</td>
                <td>{item.email}</td>
                <td>{item.address}</td>
                <td>{item.trade_license === null ? "N/A" : "Submitted"}</td>


              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default RegUsers;
