import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./PendingOrder.css"
import { Container, Table, Row, Col, Modal, Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import axios, { post } from 'axios';
import { Line, Bar } from "react-chartjs-2";
import './Wallet.css'
const AdminPendingOrder = () => {
  const [totalCommission, setTotalCommission] = useState(0);
  const [totalCoupon, setTotalCoupon] = useState(0);
  const [totalRedeemed, setTotalRedeemed] = useState(0);
  const [totalRedeemReqs, setTotalRedeemReqs] = useState(0);
  const [totalCash, setTotalCash] = useState(0);
  const [totalServiceAmount, setTotalServiceAmount] = useState(0);

  const [dataRedeemed, setDataRedeemed] = useState([]);
  const [dataRedeemReqs, setDataRedeemReqs] = useState([]);
  const [dataTransactions, setDataTransactions] = useState([]);
  const [modalOrder, setModalOrder] = useState("");
  const [modalData, setModalData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const [chartLabel, setChartLabel] = useState([]);
  const [chartData1, setChartData1] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [query, setQuery] = useState("");

  const [Allpage, setAllPage] = useState({
    currentPage: null,
    lastPage: null
  });

  const [page, setPage] = useState("Status");

  useEffect(() => {
    fetchRedeemed(1);
    fetchRedeemReqs();
    fetchAllCompleted();
  }, []);
  const history = useHistory();

  const approveRedeem = async (id) => {
    confirmAlert({
      title: 'Approve Request?',
      message: 'Only approve this request if you are done transferring the requested amount to this Vendor',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const url = "https://backend.amaderservice.com/api/redeemrequestapproved";
            var p = await post(url, {
              orderserviceid: id
            })

            fetchRedeemReqs();
            fetchRedeemed(1);

          }
        },
        {
          label: 'No',
          onClick: () => console.log('Click No')
        }
      ]
    })

  }

  const fetchRedeemed = async (page) => {
    fetch("https://backend.amaderservice.com/api/seeapprovedredeemrequest?page=" + page)
      .then((response) => response.json())
      .then((data) => {
        setDataRedeemed(data.data.reverse())
        let obj = {
          currentPage: page,
          lastPage: data.last_page
        }
        setAllPage(obj)

      })
  };

  function searchHandler() {
    const link = "https://backend.amaderservice.com/api/"
    fetch(link + "searchredeemrequest?search=" + query)
      .then((response) => response.json())
      .then((data) => {
        setDataRedeemed(data.data.reverse())
        let obj = {
          currentPage: 1,
          lastPage: data.last_page
        }
        setAllPage(obj)
      })

  }

  const fetchRedeemReqs = async () => {
    fetch("https://backend.amaderservice.com/api/seeredeemrequest")
      .then((response) => response.json())
      .then((data) => {
        setDataRedeemReqs(data.reverse())
        var a = data.reduce(function (prev, cur) {
          return parseInt(prev) + parseInt((cur.price * cur.quantity) * 0.8);
        }, 0);
        setTotalRedeemReqs(a)

      })
  };

  function formatData(q) {
    function checkStatus(status){


      if(status == 1){
        return "Complete"
      }else if(status == 7){
        return "Requested to Redeem"
      }else if(status == 8){
        return "Redeemed"
      }else{
        return "Unknown"
      }

      
    }
    let p = []
    q.map(async x => {
      let index = p.findIndex(w => w.mainorderid === x.mainorderid);
      if (index == -1) {
        let y = {
          mainorderid: x.mainorderid,
          contact_person: x.contact_person,
          contact_person_number: x.contact_person_number,
          order_address: x.order_address,
          expectedTime: x.expectedTime,
          coupon_discount_amount: x.coupon_discount_amount,
          isDigital: x.isDigital,
          status: checkStatus(x.status),
          services: [
            {
              service_id: x.orderid,
              service: x.service,
              service_name: x.service_name,
              price: x.price,
              quantity: x.quantity,
              area: x.area,
              vendor_name: x.vendor_name
            }
          ]
        }
        p.push(y)
      }
      else {
        let y = {
          service_id: x.orderid,
          service: x.service,
          service_name: x.service_name,
          price: x.price,
          quantity: x.quantity,
          area: x.area,
          vendor_name: x.vendor_name
        }
        p[index].services.push(y)
      }
    }
    )
    return p;
  }

  function formatData2(q) {
    let p = []
    q.map(async x => {

      let y = {
        mainorderid: x.mainorderid,
        coupon_discount_amount: x.coupon_discount_amount,
        isDigital: x.isDigital,
        totalServicePrice:
          x.services.reduce(function (prev, cur) {
            return parseInt(prev) + parseInt((cur.price * cur.quantity))
          },
            0),
      }
      p.push(y)

    }
    )
    return p;
  }
  const data = {
    labels: chartLabel,
    datasets: [
      {
        label: "Amount Collected",
        data: chartData1,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",

      },
    ]
  };

  const prevoptions = {
    maintainAspectRatio: false,
    scales: {

    },
  };

  const options = {
    maintainAspectRatio: false,

    indexAxis: 'x',

    responsive: true,
    plugins: {
      legend: {
        display:false,
      },
      title: {
        display: true,
        position: "left",
        text: 'Amount Collected (৳)',
      },

    },
  };

  const fetchAllCompleted = async () => {
    fetch("https://backend.amaderservice.com/api/allcompletedservices")
      .then((response) => response.json())
      .then((data) => {
        var ArrByMainOrderId = formatData(data);
        setDataTransactions(ArrByMainOrderId.reverse())
        var Arr2 = formatData2(ArrByMainOrderId);
        console.log("-----------------------------------");
        console.log(JSON.stringify(ArrByMainOrderId));





        var chartlabel = [];
        Arr2.forEach((i) => {
          chartlabel.push(i.mainorderid)
        })
        setChartLabel(chartlabel.reverse())



        var chartdata1 = [];

        Arr2.forEach((i) => {
          chartdata1.push(i.totalServicePrice - i.coupon_discount_amount)
        })
        setChartData1(chartdata1.reverse())

        var totalS = Arr2.reduce(function (prev, cur) {
          return parseInt(prev) + parseInt((cur.totalServicePrice))
        }, 0);

        var totalC = Arr2.reduce(function (prev, cur) {
          return parseInt(prev) + parseInt((cur.coupon_discount_amount))
        },
          0);

        var cashOnly = Arr2.filter(x => x.isDigital == "false")

        var totalCash = cashOnly.reduce(function (prev, cur) {
          return parseInt(prev) + parseInt(cur.totalServicePrice - cur.coupon_discount_amount)
        },
          0);
        setTotalServiceAmount(totalS)

        setTotalCoupon(totalC)

        setTotalCommission(totalS * 0.2 - totalC)
        setTotalCash(totalCash)

      })
  };

  function modalDisplay(id, data) {
    setModalOrder(id);
    setModalData(data);
    setModalVisible(true)
  }



  return (
    <div>
      <Modal
        size="xl"
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h5>
            Services in #{modalOrder}
          </h5>

          <Table responsive>
            <thead>
              <tr>
                <th>Vendor</th>
                <th>Service</th>
                <th>Service Type</th>
                <th>Area</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total Price</th>

              </tr>
            </thead>
            <tbody>
              {modalData.map((item) => (
                <tr>
                  <td>{item.vendor_name}</td>
                  <td>{item.service}</td>
                  <td>{item.service_name}</td>
                  <td>{item.area} sqft.</td>
                  <td>৳ {item.price}</td>
                  <td>{item.quantity}</td>
                  <td>৳ {item.price * item.quantity}</td>
                </tr>
              ))}


            </tbody>
          </Table>

        </Modal.Body>
        <Modal.Footer>
          <Button style={{ width: "100%", marginTop: 0 }} onClick={() => setModalVisible(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Row style={{ width: "100%", height: 50, backgroundColor: "#060b26", margin: 0, }}>
        <Col onClick={() => setPage("Status")}
          style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Status" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Status</h4>
        </Col>
        <Col onClick={() => setPage("Redeem Reqs")}
          style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Redeem Reqs" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Redeem Reqs</h4>
        </Col>
        <Col onClick={() => setPage("Redeemed")}
          style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Redeemed" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Redeemed</h4>
        </Col>
        <Col onClick={() => setPage("Transaction")}
          style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Transaction" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Transactions</h4>
        </Col>
      </Row>

      {page == "Status" &&
        <div style={{ margin: 40 }}>
          <div>
            <Line height={300} options={options} data={data} />
          </div>
          <p style={{ margin: 0, padding: 0, textAlign: "center", fontSize: 12, fontWeight: "bold", color: "#6c6c6c", marginBottom: 40 }}>Order ID</p>

          <Row md={3} style={{ margin: 0, padding: 0 }}>

            <div style={{ margin: 0, padding: 0 }}>
              <Row className="walletGrid" style={{ backgroundColor: "white", margin: 10, paddingTop: 40, paddingBottom: 30 }}>
                <Col>
                  <h1 style={{ textAlign: "center", color: "#61857c", margin: 0, padding: 0 }}>Services</h1>
                </Col>
                <Col style={{ display: 'flex', alignSelf: "center", justifyContent: 'center', }}>
                  <h1 style={{ textAlign: "center", color: "#5a00b4", }}>৳ {totalServiceAmount}</h1>
                </Col>
              </Row>

            </div>

            <div style={{ margin: 0, padding: 0 }}>
              <Row className="walletGrid" style={{ backgroundColor: "white", margin: 10, paddingTop: 40, paddingBottom: 30 }}>

                <Col>
                  <h1 style={{ textAlign: "center", color: "#61857c", margin: 0, padding: 0 }}>Coupon</h1>
                </Col>
                <Col style={{ display: 'flex', alignSelf: "center", justifyContent: 'center', }}>
                  <h1 style={{ textAlign: "center", color: "orange" }}>৳ {totalCoupon}</h1>
                </Col>

              </Row>
            </div>


            <div style={{ margin: 0, padding: 0 }}>
              <Row className="walletGrid" style={{ backgroundColor: "white", margin: 10, paddingTop: 40, paddingBottom: 30 }}>
                <Col>
                  <h1 style={{ textAlign: "center", color: "#61857c", margin: 0, padding: 0 }}>Commission</h1>
                </Col>
                <Col style={{ display: 'flex', alignSelf: "center", justifyContent: 'center', }}>
                  <h1 style={{ textAlign: "center", color: "#00b487", }}>৳ {totalCommission}</h1>
                </Col>
              </Row>

            </div>



          </Row>

          <Row md={2} style={{ margin: 0, padding: 0 , marginTop:0}}>
            <div style={{ margin: 0, padding: 0 }}>
              <Row className="walletGrid" style={{ backgroundColor: "white", margin: 10, paddingTop: 20, paddingBottom: 10 }}>

                <Col>
                  <h3 style={{ textAlign: "center", color: "#61857c", margin: 0, padding: 0 }}>Amount Collected</h3>


                </Col>
                <Col style={{ display: 'flex', alignSelf: "center", justifyContent: 'center', }}>
                  <h3 style={{ textAlign: "center", color: "#61857c" }}>৳ {totalServiceAmount - totalCoupon}</h3>


                </Col>





              </Row>

            </div>
            <div style={{ margin: 0, padding: 0 }}>
              <Row className="walletGrid" style={{ backgroundColor: "white", margin: 10, paddingTop: 20, paddingBottom: 10 }}>

                <Col>
                  <h3 style={{ textAlign: "center", color: "#61857c", margin: 0, padding: 0 }}>Vendor's Share</h3>



                </Col>
                <Col style={{ display: 'flex', alignSelf: "center", justifyContent: 'center', }}>
                  <h3 style={{ textAlign: "center", color: "#61857c" }}>৳ {totalServiceAmount * 0.8}</h3>


                </Col>

              </Row>
            </div>
          </Row>
          <Row md={2} style={{ margin: 0, padding: 0 }}>
            <div style={{ margin: 0, padding: 0 }}>
              <Row className="walletGrid" style={{ backgroundColor: "white", margin: 10, paddingTop: 20, paddingBottom: 10 }}>

                <Col>
                  <h3 style={{ textAlign: "center", color: "#61857c", margin: 0, padding: 0 }}>By Cash</h3>


                </Col>
                <Col style={{ display: 'flex', alignSelf: "center", justifyContent: 'center', }}>
                  <h3 style={{ textAlign: "center", color: "#61857c" }}>৳ {totalCash}</h3>


                </Col>





              </Row>

            </div>
            <div style={{ margin: 0, padding: 0 }}>
              <Row className="walletGrid" style={{ backgroundColor: "white", margin: 10, paddingTop: 20, paddingBottom: 10 }}>

                <Col>
                  <h3 style={{ textAlign: "center", color: "#61857c", margin: 0, padding: 0 }}>Vendor Redeemed</h3>



                </Col>
                <Col style={{ display: 'flex', alignSelf: "center", justifyContent: 'center', }}>
                  <h3 style={{ textAlign: "center", color: "#61857c" }}>৳ {totalRedeemed}</h3>


                </Col>

              </Row>
            </div>
          </Row>

          <Row md={2} style={{ margin: 0, padding: 0 }}>
            <div style={{ margin: 0, padding: 0 }}>
              <Row className="walletGrid" style={{ backgroundColor: "white", margin: 10, paddingTop: 20, paddingBottom: 10 }}>

                <Col>
                  <h3 style={{ textAlign: "center", color: "#61857c", margin: 0, padding: 0 }}>By Digital</h3>


                </Col>
                <Col style={{ display: 'flex', alignSelf: "center", justifyContent: 'center', }}>
                  <h3 style={{ textAlign: "center", color: "#61857c" }}>৳ {totalServiceAmount - totalCoupon - totalCash}</h3>


                </Col>





              </Row>

            </div>
            <div style={{ margin: 0, padding: 0 }}>
              <Row className="walletGrid" style={{ backgroundColor: "white", margin: 10, paddingTop: 20, paddingBottom: 10 }}>

                <Col>
                  <h3 style={{ textAlign: "center", color: "#61857c", margin: 0, padding: 0 }}>Vendor Requested</h3>



                </Col>
                <Col style={{ display: 'flex', alignSelf: "center", justifyContent: 'center', }}>
                  <h3 style={{ textAlign: "center", color: "#61857c" }}>৳ {totalRedeemReqs}</h3>



                </Col>

              </Row>
              <Row className="walletGrid" style={{ backgroundColor: "white", margin: 10,marginTop:20, paddingTop: 20, paddingBottom: 10 }}>

                <Col>
                  <h3 style={{ textAlign: "center", color: "#61857c", margin: 0, padding: 0 }}>Vendor Redeemable</h3>



                </Col>
                <Col style={{ display: 'flex', alignSelf: "center", justifyContent: 'center', }}>
                  <h3 style={{ textAlign: "center", color: "#61857c" }}>৳ {(totalServiceAmount * 0.8) - totalRedeemReqs - totalRedeemed}</h3>



                </Col>

              </Row>
            </div>
          </Row>

          






        </div>
      }

      {page == "Redeem Reqs" &&

        <div style={{ margin: 20 }}>
          <h1 style={{ textAlign: "center" }}>Redeem Requests ({dataRedeemReqs.length})</h1>
          {dataRedeemReqs.length > 0 &&
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Order</th>
                    <th>Order Expected At</th>
                    <th>Service</th>
                    <th>Service Type</th>
                    <th>Area</th>
                    <th>Vendor</th>
                    <th>Vendor Mobile</th>
                    <th>Vendor Email</th>
                    <th style={{ textAlign: "right" }}>Request Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {dataRedeemReqs.map((item) => (
                    <tr onClick={() => approveRedeem(item.id)}>
                      <td>{item.order_id}</td>
                      <td>{item.expectedTime}</td>
                      <td>{item.service}</td>
                      <td>{item.service_name}</td>
                      <td>{item.area} sqft.</td>

                      <td>{item.vendor_name}</td>
                      <td>{item.contact}</td>
                      <td>{item.email}</td>
                      <td style={{ textAlign: "right" }}>{`৳`} {parseInt((item.price * item.quantity) * 0.8)}</td>

                    </tr>
                  ))}
                </tbody>
              </Table>


              <p style={{ fontWeight: "bold", textAlign: "right", marginRight: 10 }}>Total Requested ৳ {totalRedeemReqs}</p>

              <div style={{ width: "100%", backgroundColor: "#d0d0d0", height: 1, marginBottom: 0 }} />
            </div>
          }
        </div>

      }

      {page == "Redeemed" &&

        <div style={{ margin: 20 }}>
{/* SEARCH AND PAGINATION START */}
<Row style={{ width: "100%", margin: 0, padding: 0 }}>
          <Col style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>
            <h4 style={{ padding: 0, color: "#060b26", textAlign: "left", }}>Redeemed</h4>

          </Col>

          <Col style={{ cursor: "pointer", textAlign: "right", padding: 0, marginTop: -5 }}>
            <form onSubmit={() => searchHandler()}>
              <input
                type="text"
                class="form-control"
                id=""
                style={{ margin: 0 }}
                placeholder="Search..."
                value={query}
                onChange={(event) => setQuery(event.target.value)}
              ></input>
            </form>

          </Col>

          <Col style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>
            <Row style={{ width: "100%", margin: 0, padding: 0 }}>

              <Col onClick={() => {
                let obj = {
                  currentPage: Allpage.currentPage - 1,
                  lastPage: Allpage.lastPage
                }

                if (Allpage.currentPage !== 1) {

                  setAllPage(obj)
                  fetchRedeemed(Allpage.currentPage - 1)

                }
              }} style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>

                <h4 className="paginationButton" style={{ padding: 0, textAlign: "right", }}>&#9664;</h4>
              </Col>

              <Col style={{ cursor: "pointer", textAlign: "center", padding: 0 }}>

                <h4 style={{ padding: 0, textAlign: "center", color: "#060b26" }}>Page {Allpage.currentPage} of {Allpage.lastPage}</h4>
              </Col>

              <Col onClick={() => {
                let obj = {
                  currentPage: Allpage.currentPage + 1,
                  lastPage: Allpage.lastPage
                }
                if (Allpage.currentPage !== Allpage.lastPage) {

                  setAllPage(obj)
                  fetchRedeemed(Allpage.currentPage + 1)

                }
              }}
                style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>

                <h4
                  className="paginationButton" style={{ padding: 0, textAlign: "left" }}>&#9654;</h4>
              </Col>

            </Row>
          </Col>


        </Row>
        {/* SEARCH AND PAGINATION END */}   
        
                  {dataRedeemed.length > 0 && <div>
            <Table responsive>
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Order Expected At</th>
                  <th>Service</th>
                  <th>Service Type</th>
                  <th>Area</th>
                  <th>Vendor</th>
                  <th>Vendor Mobile</th>
                  <th>Vendor Email</th>
                  <th style={{ textAlign: "right" }}>Redeem Amount</th>
                </tr>
              </thead>
              <tbody>
                {dataRedeemed.map((item) => (
                  <tr>
                    <td>{item.order_id}</td>
                    <td>{item.expectedTime}</td>
                    <td>{item.service}</td>
                    <td>{item.service_name}</td>
                    <td>{item.area} sqft.</td>

                    <td>{item.vendor_name}</td>
                    <td>{item.contact}</td>
                    <td>{item.email}</td>
                    <td style={{ textAlign: "right" }}>{`৳`} {parseInt((item.price * item.quantity) * 0.8)}</td>

                  </tr>
                ))}
              </tbody>
            </Table>



            <div style={{ width: "100%", backgroundColor: "#d0d0d0", height: 1, marginBottom: 0 }} />
          </div>

          }
        </div>

      }

      {page == "Transaction" &&

        <div style={{ margin: 20 }}>
          <h1 style={{ textAlign: "center" }}>Transactions ({dataTransactions.length})</h1>

          {dataTransactions.length > 0 &&
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>User</th>
                    <th>Mobile</th>
                    <th>Address</th>
                    <th>Expected At</th>
                    <th>Payment</th>
                    <th>Status</th>


                    <th>Services</th>
                    <th>Coupon</th>
                    <th>Bill</th>
                    <th>Vendor</th>
                    <th style={{ textAlign: "right" }}>Commission</th>

                  </tr>
                </thead>
                <tbody>
                  {dataTransactions.map((item) => (
                    <tr onClick={() => modalDisplay(item.mainorderid, item.services)}>
                      <td>{item.mainorderid}</td>
                      <td>{item.contact_person}</td>
                      <td>{item.contact_person_number}</td>
                      <td>{item.order_address}</td>
                      <td>{item.expectedTime}</td>
                      <td>{item.isDigital == "false" ? "Cash" : "Digital"}</td>
                      <td>{item.status}</td>


                      <td>৳ {item.services.reduce(function (prev, cur) { return parseInt(prev) + parseInt((cur.price * cur.quantity)) }, 0)}</td>
                      <td style={{ color: "orange" }}>৳ {item.coupon_discount_amount}</td>
                      <td>৳ {item.services.reduce(function (prev, cur) { return parseInt(prev) + parseInt((cur.price * cur.quantity)) }, 0) - item.coupon_discount_amount}</td>
                      <td>৳ {item.services.reduce(function (prev, cur) { return parseInt(prev) + parseInt((cur.price * cur.quantity)) }, 0) * 0.8}</td>
                      <td style={{ color: "green", textAlign: "right" }}>৳ {(item.services.reduce(function (prev, cur) { return parseInt(prev) + parseInt((cur.price * cur.quantity)) }, 0) * 0.2) - item.coupon_discount_amount}</td>

                    </tr>
                  ))}
                </tbody>
              </Table>


              <p style={{ color: "green", fontWeight: "bold", textAlign: "right", marginRight: 10 }}>Total Commission ৳ {totalCommission}</p>

              <div style={{ width: "100%", backgroundColor: "#d0d0d0", height: 1, marginBottom: 0 }} />
            </div>}

        </div>
      }
    </div>
  );
};

export default AdminPendingOrder;
