import React, { useEffect, useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Redirect, useHistory } from "react-router-dom";
import { Table, Row, Col, Button } from 'react-bootstrap';
import cartEmptyImage from "./../asset/logo.png";
import './Login.css'
const UserLogin = () => {
    //local storage data read START

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userInfo, setUserInfo] = useState([
        {
            id: "",
            name: "",
            email: "",
            address: "",
        },
    ]);


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const history = useHistory();

    const login = async () => {
        if (email == "admin@amaderservice.com" && password == "service@220721") {
            await reactLocalStorage.set("isLoggedIn", "ADMIN_YES");
            history.push("/");
            window.location.reload();
        } else {
            alert("Login Failed!")
        }

    };

    return (
        <div className="grad1" >
            <Row style={{ margin: 0, padding: 50 }}>
                <Col></Col>
                <Col style={{ boxShadow: "1px 1px 1px 1px #d1d1d1", padding: 20, borderRadius: 10, alignItems: "center", justifyContent: 'center', textAlign: "center", flexDirection: "column", backgroundColor: "white" }}>
                    <img src={cartEmptyImage} alt="John" style={{ height: 150, width: 150, borderRadius: 100, marginBottom: 10, marginTop: 20, }} />
                    <h1>Admin</h1>
                    <input
                        type="text"
                        class="form-control"
                        id=""
                        style={{ marginBottom: 20, marginTop: 10 }}
                        placeholder="Email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    ></input>

                    <input
                        type="password"
                        class="form-control"
                        id=""
                        placeholder="Password"
                        value={password}
                        minLength={8}
                        onChange={(event) => setPassword(event.target.value)}
                        required
                    ></input>

                    <Button style={{ width: "100%", marginTop: 20 }} onClick={() => login()}>
                        Login
                    </Button>
                </Col>
                <Col></Col>

            </Row>

        </div>
    );
};

export default UserLogin;
