import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./PendingOrder.css"
import { Container, Table, Row, Col, Modal, Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import axios, { post } from 'axios';

const AdminPendingOrder = () => {
  const [couponHistory, setCouponHistory] = useState([]);
  const [totalCoupon, setTotalCoupon] = useState(0);
  const [activeCoupons, setActiveCoupons] = useState([]);
  const [inactiveData, setInactiveData] = useState([]);

  const [couponStart, setCouponStart] = useState("");
  const [couponEnd, setCouponEnd] = useState("");
  const [couponPercentage, setCouponPercentage] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [modalShow, setModalShow] = useState(false);
  
  const [modalOrder, setModalOrder] = useState("");
  const [modalData, setModalData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);


  const [page, setPage] = useState("Active");

  useEffect(() => {
    fetchActiveCoupons();
    fetchCouponHistoy();
  }, []);

  function formatData(q) {
    let p = []
    q.map(async x => {
      let index = p.findIndex(w => w.mainorderid === x.mainorderid);
      if (index == -1) {
        let y = {
          mainorderid: x.mainorderid,
          contact_person: x.contact_person,
          contact_person_number: x.contact_person_number,
          order_address: x.order_address,
          expectedTime: x.expectedTime,
          coupon_discount_amount: x.coupon_discount_amount,
          isDigital: x.isDigital,
          services: [
            {
              service_id: x.orderid,
              service: x.service,
              service_name: x.service_name,
              price: x.price,
              quantity: x.quantity,
              area: x.area,
              vendor_name: x.vendor_name
            }
          ]
        }
        p.push(y)
      }
      else {
        let y = {
          service_id: x.orderid,
          service: x.service,
          service_name: x.service_name,
          price: x.price,
          quantity: x.quantity,
          area: x.area,
          vendor_name: x.vendor_name
        }
        p[index].services.push(y)
      }
    }
    )
    return p;
  }


  const fetchActiveCoupons = async () => {
    setModalShow(false);
    fetch("https://backend.amaderservice.com/api/getcoupon")
      .then((response) => response.json())
      .then(async (data) => {
        setActiveCoupons(data);
        if(data.length > 0){
          var res = await fetch("https://backend.amaderservice.com/api/allcoupons");
          var responseJson = await res.json();
          var p = responseJson.filter(x => x.id !== data[0].id)
          setInactiveData(p)
        }else{
          var res = await fetch("https://backend.amaderservice.com/api/allcoupons");
          var responseJson = await res.json();
          setInactiveData(responseJson)
        }
      })

      
  }


  const fetchCouponHistoy = async () => {
    fetch("https://backend.amaderservice.com/api/allcompletedservices")
      .then((response) => response.json())
      .then((data) => {
        var ArrByMainOrderId = formatData(data);
        var arr2 = ArrByMainOrderId.filter(x => x.coupon_discount_amount > 0)
        console.log("*********************************");
        console.log(JSON.stringify(arr2));
        console.log("*********************************");

        setCouponHistory(arr2)
        setTotalCoupon(arr2.reduce(function (prev, cur) {
          return parseInt(prev) + parseInt(cur.coupon_discount_amount);
        }, 0))

      })
  };

  const deactivateCoupon = async (id) => {
    confirmAlert({
      title: 'Deactivate Coupon?',
      message: 'Coupons deactivated will not appear in the User App',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const url = "https://backend.amaderservice.com/api/updatecoupon?couponid=" + id;
            var p = await fetch(url)
            var q = await p.json();
            fetchActiveCoupons();

          }
        },
        {
          label: 'No',
          onClick: () => console.log('Click No')
        }
      ]
    })
  }
  const createCoupon = async () => {
    const url = "https://backend.amaderservice.com/api/createcoupon";
    var response = await post(url, {
      startdate: couponStart,
      enddate: couponEnd,
      coupone_code: couponCode,
      percentage: couponPercentage
    })


    fetchActiveCoupons()
  }


  function modalDisplay(id, data) {
    setModalOrder(id);
    setModalData(data);
    setModalVisible(true)
  }

  return (
    <div>
      <Modal
        size="xl"
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h5>
            Services in #{modalOrder}
          </h5>

          <Table responsive>
            <thead>
              <tr>
                <th>Vendor</th>
                <th>Service</th>
                <th>Service Type</th>
                <th>Area</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total Price</th>

              </tr>
            </thead>
            <tbody>
              {modalData.map((item) => (
                <tr>
                  <td>{item.vendor_name}</td>
                  <td>{item.service}</td>
                  <td>{item.service_name}</td>
                  <td>{item.area} sqft.</td>
                  <td>৳ {item.price}</td>
                  <td>{item.quantity}</td>
                  <td>৳ {item.price * item.quantity}</td>
                </tr>
              ))}


            </tbody>
          </Table>

        </Modal.Body>
        <Modal.Footer>
          <Button style={{ width: "100%", marginTop: 0 }} onClick={() => setModalVisible(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
      <Modal
          size="sm"
          show={modalShow}
          onHide={() => setModalShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h5>
              New Coupon
            </h5>
            <p style={{ margin: 0, padding: 0,marginTop:20 }}>Coupon Percentage</p>

            <input
              type="text"
              class="form-control"
              id=""
              style={{ marginBottom: 0, marginTop: 10 }}
              placeholder="%"
              value={couponPercentage}
              onChange={(event) => setCouponPercentage(event.target.value)}
            ></input>
                            <p style={{ margin: 0, padding: 0,marginTop:20 }}>Coupon Code</p>


<input
              type="text"
              class="form-control"
              id=""
              style={{ marginBottom: 0, marginTop: 10 }}
              placeholder="No special characters please"
              value={couponCode}
              onChange={(event) => setCouponCode(event.target.value)}
            ></input>
                            <p style={{ margin: 0, padding: 0,marginTop:20 }}>Start Date</p>


<input
              type="date"
              class="form-control"
              id=""
              style={{ marginBottom: 0, marginTop: 10 }}
              placeholder="Enter here..."
              value={couponStart}
              onChange={(event) => setCouponStart(event.target.value)}
            ></input>
                            <p style={{ margin: 0, padding: 0,marginTop:20 }}>End Date</p>


<input
              type="date"
              class="form-control"
              id=""
              style={{ marginBottom: 0, marginTop: 10 }}
              placeholder="Enter here..."
              value={couponEnd}
              onChange={(event) => setCouponEnd(event.target.value)}
            ></input>


          </Modal.Body>
          <Modal.Footer>
            <Button style={{ width: "100%", marginTop: 0 }} onClick={() => createCoupon()}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      <Row style={{ width: "100%", height: 50, backgroundColor: "#060b26", margin: 0, }}>
        <Col onClick={() => setPage("Active")}
          style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Active" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Coupons</h4>
        </Col>
        <Col onClick={() => setPage("Coupon History")}
          style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Coupon History" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Coupon History</h4>
        </Col>
        <Col onClick={() => activeCoupons.length > 0 ? alert("You have 1 active coupon. Please deactivate it to add another Active Coupon."): setModalShow(true)}
          style={{ cursor: "pointer", textAlign: "center", backgroundColor:"" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Create Coupon</h4>
        </Col>
      </Row>

      {page == "Active" &&
        <div style={{ margin: 20, marginTop: 30 }}>
          <h1 style={{ textAlign: "center" }}>Active Coupon ({activeCoupons.length})</h1>
          {activeCoupons.length > 0 &&
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>coupone_code</th>
                    <th>startdate</th>
                    <th>enddate</th>
                    <th>Percentage</th>

                  </tr>
                </thead>
                <tbody>
                  {activeCoupons.map((item) => (
                    <tr onClick={()=>deactivateCoupon(item.id)}>
                      <td>{item.coupone_code}</td>
                      <td>{item.startdate}</td>
                      <td>{item.enddate}</td>
                      <td>{item.percentage} %</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          }
          <h1 style={{ textAlign: "center" }}>Deactivated Coupons ({inactiveData.length})</h1>

          {inactiveData.length > 0 &&
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>coupone_code</th>
                    <th>startdate</th>
                    <th>enddate</th>
                    <th>Percentage</th>

                  </tr>
                </thead>
                <tbody>
                  {inactiveData.map((item) => (
                    <tr>
                      <td>{item.coupone_code}</td>
                      <td>{item.startdate}</td>
                      <td>{item.enddate}</td>
                      <td>{item.percentage} %</td>

                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          }



        </div>
      }

      {page == "Coupon History" &&

        <div style={{ margin: 20 }}>
          <h1 style={{ textAlign: "center" }}>Coupon History ({couponHistory.length})</h1>
          {couponHistory.length > 0 &&
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>User</th>
                    <th>Mobile</th>
                    <th>Address</th>
                    <th>Expected At</th>
                    <th>Payment</th>

                    <th>Services</th>
                    <th>Coupon</th>
                    <th>Bill</th>
                    <th>Vendor</th>
                    <th style={{ textAlign: "right" }}>Commission</th>

                  </tr>
                </thead>
                <tbody>
                  {couponHistory.map((item) => (
                    <tr onClick={() => modalDisplay(item.mainorderid, item.services)}>
                      <td>{item.mainorderid}</td>
                      <td>{item.contact_person}</td>
                      <td>{item.contact_person_number}</td>
                      <td>{item.order_address}</td>
                      <td>{item.expectedTime}</td>
                      <td>{item.isDigital == "false" ? "Cash" : "Digital"}</td>

                      <td>৳ {item.services.reduce(function (prev, cur) { return parseInt(prev) + parseInt((cur.price * cur.quantity)) }, 0)}</td>
                      <td style={{ color: "orange" }}>৳ {item.coupon_discount_amount}</td>
                      <td>৳ {item.services.reduce(function (prev, cur) { return parseInt(prev) + parseInt((cur.price * cur.quantity)) }, 0) - item.coupon_discount_amount}</td>
                      <td>৳ {item.services.reduce(function (prev, cur) { return parseInt(prev) + parseInt((cur.price * cur.quantity)) }, 0) * 0.8}</td>
                      <td style={{ color: "green", textAlign: "right" }}>৳ {(item.services.reduce(function (prev, cur) { return parseInt(prev) + parseInt((cur.price * cur.quantity)) }, 0) * 0.2) - item.coupon_discount_amount}</td>

                    </tr>
                  ))}
                </tbody>
              </Table>
              
          </div>}
          
        </div>

      }

    </div>
  );
};

export default AdminPendingOrder;
