import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./PendingOrder.css"
import { Table, Row, Col } from 'react-bootstrap';

const AdminPendingOrder = ({ match }) => {
  const [pendingData, setPendingData] = useState([]);
  const [assignedData, setAssignedData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [cancelledData, setCancelledData] = useState([]);
  const [query, setQuery] = useState("");

  const [pendingPage, setPendingPage] = useState({
    currentPage: null,
    lastPage: null
  });
  const [assignedPage, setAssignedPage] = useState({
    currentPage: null,
    lastPage: null
  });
  const [completePage, setCompletePage] = useState({
    currentPage: null,
    lastPage: null
  });
  const [cancelledPage, setCancelledPage] = useState({
    currentPage: null,
    lastPage: null
  });

  const [page, setPage] = useState("Pending");
  const history = useHistory();


  useEffect(() => {
    fetchPendingData(1);
    fetchAssignedData(1);
    fetchCompleteData(1);
    fetchCancelledData(1);

    setPage(match.params.id)
  }, [match.params.id]);


  function fetchPendingData(page) {
    fetch("https://backend.amaderservice.com/api/pendingOrders?page=" + page)
      .then((response) => response.json())
      .then((data) => {
        setPendingData(data.data.reverse())
        let obj = {
          currentPage: page,
          lastPage: data.last_page
        }
        setPendingPage(obj)
      })
  }


  function fetchAssignedData(page) {
    fetch("https://backend.amaderservice.com/api/assignedOrders?page=" + page)
      .then((response) => response.json())
      .then((data) => {
        setAssignedData(data.data.reverse())
        let obj = {
          currentPage: page,
          lastPage: data.last_page
        }
        setAssignedPage(obj)
      })
  }

  function fetchCompleteData(page) {
    fetch("https://backend.amaderservice.com/api/allcompletedorders?page=" + page)
      .then((response) => response.json())
      .then((data) => {
        setCompleteData(data.data.reverse())
        let obj = {
          currentPage: page,
          lastPage: data.last_page
        }
        setCompletePage(obj)
      })
  }
  
  function fetchCancelledData(page) {
    fetch("https://backend.amaderservice.com/api/allcanceledorders?page=" + page)
      .then((response) => response.json())
      .then((data) => {
        setCancelledData(data.data.reverse())
        let obj = {
          currentPage: page,
          lastPage: data.last_page
        }
        setCancelledPage(obj)
      })
  }

  function searchHandler(type) {
    const link = "https://backend.amaderservice.com/api/"
    if (type == "pending") {
      fetch(link + "SearchPendingOrders?search=" + query)
        .then((response) => response.json())
        .then((data) => {
          setPendingData(data.data.reverse())
          let obj = {
            currentPage: 1,
            lastPage: data.last_page
          }
          setPendingPage(obj)
        })
    }

    if (type == "assigned") {
      fetch(link + "SearchAssignedOrders?search=" + query)
        .then((response) => response.json())
        .then((data) => {
          setPendingData(data.data.reverse())
          let obj = {
            currentPage: 1,
            lastPage: data.last_page
          }
          setAssignedPage(obj)
        })
    }

    if (type == "complete") {
      fetch(link + "SearchAllCompletedOrders?search=" + query)
        .then((response) => response.json())
        .then((data) => {
          setCompleteData(data.data.reverse())
          let obj = {
            currentPage: 1,
            lastPage: data.last_page
          }
          setCompletePage(obj)
        })
    }

    if (type == "cancelled") {
      fetch(link + "SearchAllCancelOrders?search=" + query)
        .then((response) => response.json())
        .then((data) => {
          setCancelledData(data.data.reverse())
          let obj = {
            currentPage: 1,
            lastPage: data.last_page
          }
          setCancelledPage(obj)
        })
    }
  }

  function redirectPending(order_id) {
    history.push(`/pendingDetails/${order_id}`);
  }

  function redirectAssigned(order_id) {
    history.push(`/assignedDetails/${order_id}`);
  }

  function redirectCompleted(order_id) {
    history.push(`/completedDetails/${order_id}`);
  }

  function redirectCancelled(id) {
    history.push(`/cancelledDetails/${id}`);

  }


  return (
    <div>
      <Row style={{ width: "100%", height: 50, backgroundColor: "#060b26", margin: 0, }}>
        <Col onClick={() => setPage("Pending")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Pending" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Pending</h4>
        </Col>
        <Col onClick={() => setPage("Assigned")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Assigned" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Assigned</h4>
        </Col>
        <Col onClick={() => setPage("Complete")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Complete" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Complete</h4>
        </Col>
        <Col onClick={() => setPage("Cancelled")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Cancelled" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Cancelled</h4>
        </Col>
        <Col onClick={() => history.push(`/createOrder`)} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Create" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Create</h4>
        </Col>
      </Row>
      {page == "Pending" &&

        <div style={{ margin: 20 }}>
          {/* SEARCH AND PAGINATION START */}
          <Row style={{ width: "100%", margin: 0, padding: 0 }}>
            <Col style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>
              <h4 style={{ padding: 0, color: "#060b26", textAlign: "left", }}>Pending Orders</h4>

            </Col>

            <Col style={{ cursor: "pointer", textAlign: "right", padding: 0, marginTop: -5 }}>
              <form onSubmit={() => searchHandler("pending")}>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  style={{ margin: 0 }}
                  placeholder="Search..."
                  value={query}
                  onChange={(event) => setQuery(event.target.value)}
                ></input>
              </form>

            </Col>

            <Col style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>
              <Row style={{ width: "100%", margin: 0, padding: 0 }}>

                <Col onClick={() => {
                  let obj = {
                    currentPage: pendingPage.currentPage - 1,
                    lastPage: pendingPage.lastPage
                  }

                  if(pendingPage.currentPage !== 1){

                    setPendingPage(obj)
                    fetchPendingData(pendingPage.currentPage - 1)
                    
                  }
                }} style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>

                  <h4 className="paginationButton" style={{ padding: 0, textAlign: "right", }}>&#9664;</h4>
                </Col>

                <Col style={{ cursor: "pointer", textAlign: "center", padding: 0 }}>

                  <h4 style={{ padding: 0, textAlign: "center", color: "#060b26" }}>Page {pendingPage.currentPage} of {pendingPage.lastPage}</h4>
                </Col>

                <Col onClick={() => {
                  let obj = {
                    currentPage: pendingPage.currentPage + 1,
                    lastPage: pendingPage.lastPage
                  }
                  if(pendingPage.currentPage !== pendingPage.lastPage){

                    setPendingPage(obj)
                    fetchPendingData(pendingPage.currentPage + 1)
                    
                  }
                }}
                  style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>

                  <h4
                    className="paginationButton" style={{ padding: 0, textAlign: "left" }}>&#9654;</h4>
                </Col>

              </Row>
            </Col>


          </Row>
          {/* SEARCH AND PAGINATION END */}

          {pendingData.length > 0 &&
            <Table responsive>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>User</th>
                  <th>Contact Person</th>
                  <th>Mobile</th>
                  <th>Zone</th>
                  <th>Address</th>
                  <th>Expected At</th>
                </tr>
              </thead>
              <tbody>
                {pendingData.map((item) => (
                  <tr key={item.id} onClick={() => redirectPending(item.order_id)}>
                    <td>{item.order_id}</td>
                    <td>{item.name}</td>

                    <td >{item.contact_person}</td>
                    <td>{item.contact_person_number}</td>
                    <td>{item.zone}</td>

                    <td>{item.order_address}</td>
                    <td>{item.expectedTime}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        </div>
      }

      {page == "Assigned" &&
        <div style={{ margin: 20 }}>
          {/* SEARCH AND PAGINATION START */}
          <Row style={{ width: "100%", margin: 0, padding: 0 }}>
            <Col style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>
              <h4 style={{ padding: 0, color: "#060b26", textAlign: "left", }}>Assigned Orders</h4>

            </Col>

            <Col style={{ cursor: "pointer", textAlign: "right", padding: 0, marginTop: -5 }}>
              <form onSubmit={() => searchHandler("assigned")}>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  style={{ margin: 0 }}
                  placeholder="Search..."
                  value={query}
                  onChange={(event) => setQuery(event.target.value)}
                ></input>
              </form>

            </Col>

            <Col style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>
              <Row style={{ width: "100%", margin: 0, padding: 0 }}>

                  <Col onClick={() => {
                    let obj = {
                      currentPage: assignedPage.currentPage - 1,
                      lastPage: assignedPage.lastPage
                    }
                    if(assignedPage.currentPage !== 1){

                    setAssignedPage(obj)
                    fetchAssignedData(assignedPage.currentPage - 1)
                    }
                  }} style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>

                    <h4 className="paginationButton" style={{ padding: 0, textAlign: "right", }}>&#9664;</h4>
                  </Col>
                
                <Col style={{ cursor: "pointer", textAlign: "center", padding: 0 }}>

                  <h4 style={{ padding: 0, textAlign: "center", color: "#060b26" }}>Page {assignedPage.currentPage} of {assignedPage.lastPage}</h4>
                </Col>
                  <Col onClick={() => {
                    let obj = {
                      currentPage: assignedPage.currentPage + 1,
                      lastPage: assignedPage.lastPage
                    }
                    if(assignedPage.currentPage !== assignedPage.lastPage){

                    setAssignedPage(obj)
                    fetchAssignedData(assignedPage.currentPage + 1)
                    }
                  }}
                    style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>

                    <h4
                      className="paginationButton" style={{ padding: 0, textAlign: "left" }}>&#9654;</h4>
                  </Col>
                
              </Row>
            </Col>


          </Row>
          {/* SEARCH AND PAGINATION END */}

          {assignedData.length > 0 &&
            <Table responsive>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>User</th>

                  <th>Contact Person</th>
                  <th>Mobile</th>
                  <th>Zone</th>

                  <th>Address</th>
                  <th>Expected At</th>
                </tr>
              </thead>
              <tbody>
                {assignedData.map((item) => (
                  <tr onClick={() => redirectAssigned(item.mainorderid)}>
                    <td>{item.mainorderid}</td>
                    <td>{item.name}</td>

                    <td >{item.contact_person}</td>
                    <td>{item.contact_person_number}</td>
                    <td >{item.zone}</td>

                    <td>{item.order_address}</td>
                    <td>{item.expectedTime}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        </div>
      }

      {page == "Complete" &&
        <div style={{ margin: 20 }}>
{/* SEARCH AND PAGINATION START */}
<Row style={{ width: "100%", margin: 0, padding: 0 }}>
            <Col style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>
              <h4 style={{ padding: 0, color: "#060b26", textAlign: "left", }}>Complete Orders</h4>

            </Col>

            <Col style={{ cursor: "pointer", textAlign: "right", padding: 0, marginTop: -5 }}>
              <form onSubmit={() => searchHandler("complete")}>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  style={{ margin: 0 }}
                  placeholder="Search..."
                  value={query}
                  onChange={(event) => setQuery(event.target.value)}
                ></input>
              </form>

            </Col>

            <Col style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>
              <Row style={{ width: "100%", margin: 0, padding: 0 }}>

                  <Col onClick={() => {
                    let obj = {
                      currentPage: completePage.currentPage - 1,
                      lastPage: completePage.lastPage
                    }
                    if(completePage.currentPage !== 1){

                    setCompletePage(obj)
                    fetchCompleteData(completePage.currentPage - 1)
                    }
                  }} style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>

                    <h4 className="paginationButton" style={{ padding: 0, textAlign: "right", }}>&#9664;</h4>
                  </Col>
                
                <Col style={{ cursor: "pointer", textAlign: "center", padding: 0 }}>

                  <h4 style={{ padding: 0, textAlign: "center", color: "#060b26" }}>Page {completePage.currentPage} of {completePage.lastPage}</h4>
                </Col>
                  <Col onClick={() => {
                    let obj = {
                      currentPage: completePage.currentPage + 1,
                      lastPage: completePage.lastPage
                    }
                    if(completePage.currentPage !== completePage.lastPage){

                    setCompletePage(obj)
                    fetchCompleteData(completePage.currentPage + 1)
                    }
                  }}
                    style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>

                    <h4
                      className="paginationButton" style={{ padding: 0, textAlign: "left" }}>&#9654;</h4>
                  </Col>
                
              </Row>
            </Col>


          </Row>
          {/* SEARCH AND PAGINATION END */}          
          {completeData.length > 0 &&
            <Table responsive>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>User</th>

                  <th>Contact Person</th>
                  <th>Mobile</th>
                  <th>Zone</th>
                  <th>Address</th>
                  <th>Expected At</th>
                </tr>
              </thead>
              <tbody>
                {completeData.map((item) => (
                  <tr onClick={() => redirectCompleted(item.mainorderid)}>
                    <td>{item.mainorderid}</td>
                    <td>{item.name}</td>

                    <td >{item.contact_person}</td>
                    <td>{item.contact_person_number}</td>
                    <td>{item.zone}</td>

                    <td>{item.order_address}</td>
                    <td>{item.expectedTime}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        </div>
      }

      {page == "Cancelled" &&
        <div style={{ margin: 20 }}>
{/* SEARCH AND PAGINATION START */}
<Row style={{ width: "100%", margin: 0, padding: 0 }}>
            <Col style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>
              <h4 style={{ padding: 0, color: "#060b26", textAlign: "left", }}>Cancelled Orders</h4>

            </Col>

            <Col style={{ cursor: "pointer", textAlign: "right", padding: 0, marginTop: -5 }}>
              <form onSubmit={() => searchHandler("cancelled")}>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  style={{ margin: 0 }}
                  placeholder="Search..."
                  value={query}
                  onChange={(event) => setQuery(event.target.value)}
                ></input>
              </form>

            </Col>

            <Col style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>
              <Row style={{ width: "100%", margin: 0, padding: 0 }}>

                  <Col onClick={() => {
                    let obj = {
                      currentPage: cancelledPage.currentPage - 1,
                      lastPage: cancelledPage.lastPage
                    }
                    if(cancelledPage.currentPage !== 1){

                    setCancelledPage(obj)
                    fetchCancelledData(cancelledPage.currentPage - 1)
                    }
                  }} style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>

                    <h4 className="paginationButton" style={{ padding: 0, textAlign: "right", }}>&#9664;</h4>
                  </Col>
                
                <Col style={{ cursor: "pointer", textAlign: "center", padding: 0 }}>

                  <h4 style={{ padding: 0, textAlign: "center", color: "#060b26" }}>Page {cancelledPage.currentPage} of {cancelledPage.lastPage}</h4>
                </Col>
                  <Col onClick={() => {
                    let obj = {
                      currentPage: cancelledPage.currentPage + 1,
                      lastPage: cancelledPage.lastPage
                    }
                    if(cancelledPage.currentPage !== cancelledPage.lastPage){

                    setCancelledPage(obj)
                    fetchCancelledData(cancelledPage.currentPage + 1)
                    }
                  }}
                    style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>

                    <h4
                      className="paginationButton" style={{ padding: 0, textAlign: "left" }}>&#9654;</h4>
                  </Col>
                
              </Row>
            </Col>


          </Row>
          {/* SEARCH AND PAGINATION END */}          
          {cancelledData.length > 0 &&
            <Table responsive>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>User</th>

                  <th>Contact Person</th>
                  <th>Mobile</th>
                  <th>Address</th>
                  <th>Expected At</th>
                </tr>
              </thead>
              <tbody>
                {cancelledData.map((item) => (
                  <tr onClick={() => redirectCancelled(item.mainorderid)}>
                    <td>{item.mainorderid}</td>
                    <td>{item.name}</td>

                    <td >{item.contact_person}</td>
                    <td>{item.contact_person_number}</td>
                    <td>{item.order_address}</td>
                    <td>{item.expectedTime}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        </div>
      }


    </div>
  );
};

export default AdminPendingOrder;
