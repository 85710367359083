import React, { useEffect, useState } from "react";
import cartEmptyImage from "./../asset/logo.png";
import { reactLocalStorage } from "reactjs-localstorage";
import * as FaIcons from 'react-icons/fa';
import { confirmAlert } from "react-confirm-alert";
import { Table, Row, Col, Button, Container } from 'react-bootstrap';
import './Dashboard.css'
import { useHistory } from "react-router-dom";
function Home() {

  const [pendingCount, setPendingCount] = useState(0);
  const [assignedCount, setAssignedCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [vendorCount, setVendorCount] = useState(0);
  const [serviceCount, setServiceCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [commission, setCommission] = useState(0);

  const [locationCount, setLocationCount] = useState(0);
  const [adminMsg, setAdminMsg] = useState("");

  const history = useHistory();



  function formatData(q) {
    let p = []
    q.map(async x => {
      let index = p.findIndex(w => w.mainorderid === x.mainorderid);
      if (index == -1) {
        let y = {
          mainorderid: x.mainorderid,
          contact_person: x.contact_person,
          contact_person_number: x.contact_person_number,
          order_address: x.order_address,
          expectedTime: x.expectedTime,
          coupon_discount_amount: x.coupon_discount_amount,
          isDigital: x.isDigital,
          services: [
            {
              service_id: x.orderid,
              service: x.service,
              service_name: x.service_name,
              price: x.price,
              quantity: x.quantity,
              area: x.area,
              vendor_name: x.vendor_name
            }
          ]
        }
        p.push(y)
      }
      else {
        let y = {
          service_id: x.orderid,
          service: x.service,
          service_name: x.service_name,
          price: x.price,
          quantity: x.quantity,
          area: x.area,
          vendor_name: x.vendor_name
        }
        p[index].services.push(y)
      }
    }
    )
    return p;
  }

  function formatData2(q) {
    let p = []
    q.map(async x => {

      let y = {
        mainorderid: x.mainorderid,
        coupon_discount_amount: x.coupon_discount_amount,
        isDigital: x.isDigital,
        totalServicePrice:
          x.services.reduce(function (prev, cur) {
            return parseInt(prev) + parseInt((cur.price * cur.quantity))
          },
            0),
      }
      p.push(y)

    }
    )
    return p;
  }


  useEffect(() => {

    setAdminMsg(getAdminMsg())

    fetch("https://backend.amaderservice.com/api/pendingOrders")
      .then((response) => response.json())
      .then((data) => setPendingCount(data.length))

    fetch("https://backend.amaderservice.com/api/assignedOrders")
      .then((response) => response.json())
      .then((data) => setAssignedCount(data.length))

      fetch("https://backend.amaderservice.com/api/registeredusers")
      .then((response) => response.json())
      .then((data) => setUserCount(data.length))      

      fetch("https://backend.amaderservice.com/api/vendorlist")
      .then((response) => response.json())
      .then((data) => setVendorCount(data.length))

      fetch("https://backend.amaderservice.com/api/allserviceadmin")
      .then((response) => response.json())
      .then((data) => setServiceCount(data.length))

      fetch("https://backend.amaderservice.com/api/all_location")
      .then((response) => response.json())
      .then((data) => setLocationCount(data.length))

      fetch("https://backend.amaderservice.com/api/allcompletedservices")
      .then((response) => response.json())
      .then((data) => {
        var ArrByMainOrderId = formatData(data);

        var Arr2 = formatData2(ArrByMainOrderId);
        setCompletedCount(Arr2.length)

        var comm = data.reduce(function (prev, cur) {
          return parseInt(prev) + parseInt((cur.price * cur.quantity) - cur.coupon_discount_amount);
        }, 0); 

        var totalS = Arr2.reduce(function (prev, cur) {
          return parseInt(prev) + parseInt((cur.totalServicePrice))
        }, 0);

        var totalC = Arr2.reduce(function (prev, cur) {
          return parseInt(prev) + parseInt((cur.coupon_discount_amount))
        },
          0);        
          setCommission(totalS * 0.2 - totalC)

      })
      



  }, []);

  function getAdminMsg(){
    var myDate = new Date();
    var hrs = myDate.getHours();

    var greet;

    if (hrs < 12)
      greet = 'Good Morning';
    else if (hrs >= 12 && hrs <= 17)
      greet = 'Good Afternoon';
    else if (hrs >= 17 && hrs <= 24)
      greet = 'Good Evening';

      return greet;

  }

  


  function MyGrid(props) {
    return (
      <div className="innerGrid" style={{ backgroundColor: props.bgcolor, padding: 20, paddingTop: 80, paddingBottom: 80, borderRadius: 30, }}>
        <h1 style={{ textAlign: "center", color: "white" }}>{props.number}</h1>
        <h2 style={{ textAlign: "center", color: "white" }}>{props.title}</h2>
      </div>
    )
  }

  
  return (
    <div className="grad2">

      <h3 style={{color:"white",textAlign:"center"}}>{adminMsg}, Admin! </h3>
      
      
      <Container fluid>
        <Row xs={1} md={4} >
          <Col className="grids" onClick={()=>history.push("/orders/Pending")} ><MyGrid bgcolor="#028090" number={pendingCount} title="Pending Orders" /></Col>
          <Col className="grids" onClick={()=>history.push("/orders/Assigned")}><MyGrid bgcolor="#025d90" number={assignedCount} title="Assigned Orders" /></Col>
          <Col className="grids" onClick={()=>history.push("/orders/Complete")}><MyGrid bgcolor="#006666" number={completedCount} title="Completed Orders" /></Col>
          <Col className="grids" onClick={()=>history.push("/wallet")}><MyGrid bgcolor="#2a3457" number={"৳ "+commission} title="Commission Earned" /></Col>

          <Col className="grids" onClick={()=>history.push("/users")}><MyGrid bgcolor="#575366" number={userCount} title="Registered Users" /></Col>
          <Col className="grids" onClick={()=>history.push("/vendors")}><MyGrid bgcolor="#01535e" number={vendorCount} title="Registered Vendors" /></Col>
          <Col className="grids" onClick={()=>history.push("/services")}><MyGrid bgcolor="#54678f" number={serviceCount} title="Total Services" /></Col>
          <Col className="grids" onClick={()=>history.push("/locations")}><MyGrid bgcolor="#3797a0" number={locationCount} title="Location Zones" /></Col>


        </Row>
      </Container>





    </div>


  );
}

export default Home;
