const data = {
    minidetails: [
        {
            id: null,
            mini: ""
        }
    ],
    includeHeading: [
        {
            id: null,
            includeHeading: ""
        }
    ],
    excludeHeading: [
        {
            id: null,
            excludeHeading: ""
        }
    ],
    servicefeatures: [
        {
            id: null,
            featuresHeading: ""
        },
    ],
    faq: [
        {
            id: null,
            answer: "",
            question: "",
            service_type_id: null,
            created_at: "",
            updated_at: ""
        },
    ],
    tandc: [
        {
            id: null,
            termsHeading: "",
            termsDetails: ""
        }
    ],
    choose: [
        {
            id: null,
            chooseHeading: "",
            chooseDetails: ""
        }
    ],
    nightshifting: "",
    details: "",
    pricing: "",
    payment: "",
    thirdheading: ""
}

export default data;
