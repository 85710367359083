import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./PendingOrder.css"
import { Table, Row, Col, Modal, Button } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import dataFormat from './secondLayerDetailsFormat';
import axios, { post } from 'axios';
import noImage from './../asset/noImage.jpg';
const AdminPendingOrder = () => {


  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [firstId, setFirstId] = useState(null);
  const [secondId, setSecondId] = useState(null);
  const [thirdId, setThirdId] = useState(null);

  const [discount, setDiscount] = useState("");

  const [details, setDetails] = useState("");

  const [startsFromAmount, setStartsFromAmount] = useState("");
  const [cover, setCover] = useState("");
  const [existingCover, setExistingCover] = useState("");

  const [modalShow, setModalShow] = useState(false);





  useEffect(() => {
    fetch_First()
  }, []);
  const history = useHistory();

  const fetch_First = async () => {
    fetch("https://backend.amaderservice.com/api/showservice")
      .then((response) => response.json())
      .then((data) => setFirst(data.reverse()))
  };

  const fetch_Second = async (id) => {

    setFirstId(id)
    fetch("https://backend.amaderservice.com/api/subcatagory?maincatagoryid=" + id)
      .then((response) => response.json())
      .then((data) => setSecond(data.reverse()))
  };


  const fetch_Third = async (id) => {
    setSecondId(id)
    setThirdId(null);
    setDetails("")
    setExistingCover("")
    setCover("")
    setDiscount("")
    setStartsFromAmount("")
    fetch("https://backend.amaderservice.com/api/servicelist?subcatagoryid=" + id)
      .then((response) => response.json())
      .then((data) => setThird(data.reverse()))
  };



  const fetchThirdDetails = async (id) => {
    setThirdId(id)
    var newArray = third.filter(function (el) { return el.id == id });
    setDetails(newArray[0].service_description);
    setDiscount(newArray[0].discount);
    setStartsFromAmount(newArray[0].starts_from);
    setExistingCover(newArray[0].service_icon);






  };

  //----------------------------------------------------------------------------------------------------------SUBMIT FORM STARTS





  async function submit() {

    const url = "https://backend.amaderservice.com/api/updateservice";

    var res = await post(url, {
      serviceid: thirdId,
      iconfile: cover,
      description: details,
      starts_from: startsFromAmount

    })


    if (res.data.status == "updated") {
      setModalShow(true)
    } else {
      alert("failed")
    }

  }

  async function submitDiscount() {

    const data = await fetch(
      `https://backend.amaderservice.com/api/setdiscount?serviceid=` + thirdId + "&amount=" + discount
    );
    const item = await data.json();
    if (item.status == "success") {
      setModalShow(true)

    } else {
      alert("error");

    }

  }

  function modalHide() {
    fetch_Third(secondId)
    setModalShow(false);
  }


  const uploadCover = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
      return;
    createCover(files[0]);
  }
  const createCover = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setCover(e.target.result);
    };

    reader.readAsDataURL(file);
  }

  return (
    <div>
      <Modal
        size="sm"
        show={modalShow}
        onHide={() => modalHide()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h2 className="text-success">

            Successful
          </h2>
          <h5>

            Details updated
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => modalHide()}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Row style={{ width: "100%", height: 50, backgroundColor: "#060b26", margin: 0, }}>
        <Col onClick={() => history.push('/services')} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>All Services</h4>
        </Col>
        <Col onClick={() => history.push("/servicesAdd")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Add Service</h4>
        </Col>
        <Col onClick={() => history.push("/subCategoryEdit")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Edit Sub Category</h4>
        </Col>
        <Col style={{ cursor: "pointer", textAlign: "center", backgroundColor: "#ffffff20" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Edit Service</h4>
        </Col>
        <Col onClick={() => history.push("/servicesDelete")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Delete Service</h4>
        </Col>
      </Row>


      <div style={{ margin: 20 }}>
        <h1 style={{ textAlign: "center", marginTop: 0, }}>Edit Service</h1>

        <Row style={{ textAlign: "center", paddingBottom: 0, marginBottom: -20 }}>
          <Col md={3} style={{ textAlign: "center", paddingBottom: 0, marginBottom: 0 }}>
            <div style={{ cursor: "pointer", alignItems: "center", justifyContent: 'center', padding: 5, backgroundColor: "", margin: 20, borderRadius: 5 }}>
              <p style={{ margin: 0, padding: 0, fontSize: 18, fontWeight: "bold", color: "black" }}>Category ({first.length})</p>
            </div>
          </Col>
          <Col md={3} style={{ textAlign: "center", paddingBottom: 0, marginBottom: 0 }}>
            <div style={{ cursor: "pointer", alignItems: "center", justifyContent: 'center', padding: 5, backgroundColor: "", margin: 20, borderRadius: 5 }}>
              <p style={{ margin: 0, padding: 0, fontSize: 18, fontWeight: "bold", color: "black" }}>Sub Category ({second.length})</p>
            </div>
          </Col>
          <Col md={3} style={{ textAlign: "center", paddingBottom: 0, marginBottom: 0 }}>
            <div style={{ cursor: "pointer", alignItems: "center", justifyContent: 'center', padding: 5, backgroundColor: "", margin: 20, borderRadius: 5 }}>
              <p style={{ margin: 0, padding: 0, fontSize: 18, fontWeight: "bold", color: "black" }}>Services ({third.length})</p>
            </div>
          </Col>
          <Col md={3} style={{ textAlign: "center", paddingBottom: 0, marginBottom: 0 }}>
            <div style={{ cursor: "pointer", alignItems: "center", justifyContent: 'center', padding: 5, backgroundColor: "#123245", margin: 20, }}>
              <p style={{ margin: 0, padding: 0, fontSize: 18, fontWeight: "bold", color: "white" }}>Edit Service</p>
            </div>
          </Col>

        </Row>




        <Row>
          <Col md={3} style={{ textAlign: "center", }}>
            {first.map((item) => (
              <div onClick={() => {
                fetch_Second(item.id)
                setFirstId(item.id)

              }} style={{ border: "1px solid #2a345750", cursor: "pointer", backgroundColor: item.id == firstId ? "#01535e" : "", color: item.id == firstId ? "white" : "black", marginLeft: 20, marginRight: 20, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
                <p style={{ margin: 0, padding: 0 }} >{item.category_name} </p>

              </div>
            ))}
          </Col>

          <Col md={3} style={{ textAlign: "center" }}>
            {second.map((item) => (
              <div onClick={() => {
                fetch_Third(item.id)
                setSecondId(item.id)

              }} style={{ border: "1px solid #2a345750", cursor: "pointer", backgroundColor: item.id == secondId ? "#2a3457" : "", color: item.id == secondId ? "white" : "black", marginLeft: 20, marginRight: 20, marginBottom: 0, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
                <p style={{ margin: 0, padding: 0 }}>{item.service_type_name} </p>

              </div>
            ))}
          </Col>

          <Col md={3} style={{ textAlign: "center" }}>
            {third.map((item) => (
              <div onClick={() => fetchThirdDetails(item.id)} style={{ border: "1px solid #2a345750", cursor: "pointer", backgroundColor: item.id == thirdId ? "#2a3457" : "", color: item.id == thirdId ? "white" : "black", marginLeft: 20, marginRight: 20, marginBottom: 0, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
                <p style={{ margin: 0, padding: 0 }}>{item.service_name} </p>

              </div>
            ))}
          </Col>


          <Col md={3} style={{ textAlign: "center", }}>
            <div style={{ backgroundColor: "#123245", color: "white", margin: 20, marginTop: 0, borderRadius: 0, alignItems: "center", justifyContent: 'center', padding: 5 }}>


              {/**------------------------------------------------------------------------------ PHOTO START */}
              <div style={{ marginTop: "10px", margin: 20 }}>
                <h4 style={{ color: "white" }}>
                  Choose Image
                </h4>
                <img src={existingCover} alt="John" onError={() => setExistingCover(noImage)} style={{ backgroundColor: "grey", height: "auto", width: "90%", marginBottom: 10, marginTop: 20, }} />

                <p style={{ padding: 0, margin: 0, color: "grey", textAlign: "left" }}>Cover</p>

                <input
                  style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                  className="form-control"
                  type="file"
                  name="coverfile"
                  placeholder="Cover File"
                  onChange={uploadCover} />


              </div>






              {/**------------------------------------------------------------------------------ DETAILS START*/}
              <div style={{ width: "100%", backgroundColor: "", height: 1, marginBottom: 0 }} />
              <div style={{ marginTop: "10px", marginBottom: 20 }}>
                <h4 style={{ color: "white" }}>
                  Details
                </h4>

                <Row style={{ margin: 10 }}>
                  <Col md={12} style={{ textAlign: "center", marginBottom: 0 }}>
                    <textarea

                      style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey", height: 150 }}
                      name="details"
                      className="form-control"
                      placeholder="Add Details"
                      value={details}
                      onChange={(event) => setDetails(event.target.value)}
                    />

                  </Col>

                </Row>
              </div>
              {/**------------------------------------------------------------------------------ STARTS FROM START*/}

              <div style={{ width: "100%", backgroundColor: "", height: 1, marginBottom: 0 }} />
              <div style={{ marginTop: "10px", marginBottom: 20 }}>
                <h4 style={{ color: "white" }}>
                  Starts From
                </h4>

                <Row style={{ margin: 10 }}>
                  <Col md={12} style={{ textAlign: "center", marginBottom: 0 }}>
                    <input

                      style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                      name="details"
                      className="form-control"
                      placeholder="৳"
                      value={startsFromAmount}
                      onChange={(event) => setStartsFromAmount(event.target.value)}
                    />

                  </Col>

                </Row>
              </div>

              <Button style={{ width: "50%", marginBottom: 20 }} onClick={() => { thirdId !== null ? submit() : alert("Please Select a Service First") }}>Submit</Button>

              <div style={{ width: "100%", backgroundColor: "", height: 1, marginBottom: 0 }} />
              <div style={{ marginTop: "10px", marginBottom: 0 }}>
                <h4 style={{ color: "white" }}>
                  Discount (%)
                </h4>

                <Row style={{ margin: 10 }}>
                  <Col md={12} style={{ textAlign: "center", marginBottom: 20 }}>
                    <input

                      style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                      name="discount"
                      className="form-control"
                      placeholder="%"
                      value={discount}
                      onChange={(event) => setDiscount(event.target.value)}
                    />

                  </Col>

                </Row>
              </div>

              <Button style={{ width: "50%", marginBottom: 100 }} onClick={() => { thirdId !== null ? submitDiscount() : alert("Please Select a Service First") }}>Submit</Button>




            </div>

          </Col>

        </Row>
      </div>


    </div>
  );
};

export default AdminPendingOrder;
