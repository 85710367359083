import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./PendingOrder.css"
import { Table, Row, Col, Modal, Button } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert'; // Import

const AdminPendingOrder = () => {
  const [allServices, setAllServices] = useState([]);
  const [page, setPage] = useState("addService");

  const [modalShow, setModalShow] = useState(false);
  const [subServiceModalShow, setSubServiceModalShow] = useState(false);

  const [subServiceModalName, setSubServiceModalName] = useState("");
  const [subServiceModalPrice, setSubServiceModalPrice] = useState("");
  const [subServiceModalType, setSubServiceModalType] = useState("");

  const [modalType, setModalType] = useState();
  const [modalInput, setModalInput] = useState("");

  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);
  const [fourth, setFourth] = useState([]);

  const [firstId, setFirstId] = useState(null);
  const [secondId, setSecondId] = useState(null);
  const [thirdId, setThirdId] = useState(null);
  const [fourthId, setFourthId] = useState(null);





  useEffect(() => {
    fetch_First()
  }, []);
  const history = useHistory();


  const fetch_First = async () => {
    fetch("https://backend.amaderservice.com/api/showservice")
      .then((response) => response.json())
      .then((data) => setFirst(data.reverse()))
  };

  const fetch_Second = async (id) => {

    setFirstId(id)
    fetch("https://backend.amaderservice.com/api/subcatagory?maincatagoryid=" + id)
      .then((response) => response.json())
      .then((data) => setSecond(data.reverse()))
  };

  const fetch_Third = async (id) => {
    setSecondId(id)
    fetch("https://backend.amaderservice.com/api/servicelist?subcatagoryid=" + id)
      .then((response) => response.json())
      .then((data) => setThird(data.reverse()))
  };

  const fetch_Fourth = async (id) => {
    setThirdId(id)
    fetch("https://backend.amaderservice.com/api/finalServiceList?serviceid=" + id)
      .then((response) => response.json())
      .then((data) => setFourth(data.reverse()))
  };

  function modalHide(){
    fetch_First()
    fetch_Second(firstId)
    fetch_Third(secondId)
    fetch_Fourth(thirdId)
    setModalShow(false)
  }


  const deleteFirst = async (id) => {
    var res = await fetch("https://backend.amaderservice.com/api/subcatagory?maincatagoryid=" + id);
    var responseJson = await res.json();
    var l = responseJson.length;
    if(l === 0){
      confirmAlert({
        title: 'Confirm delete',
        message: 'Are you sure you want to delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              const link = "https://backend.amaderservice.com/api/mainservice?msid=" + id;
              var res = await fetch(link);
              var responseJson = res.json()
              if(responseJson){
                modalHide();
              }else{
                alert("Failed!")
              }  
            }
          },
          {
            label: 'No',
            onClick: () => console.log('Click No')
          }
        ]
      });         
    }else{
      alert("Cannot delete this Category as it is not empty")
    }
  }

  const deleteSecond = async (id) => {
    var res = await fetch("https://backend.amaderservice.com/api/servicelist?subcatagoryid=" + id);
    var responseJson = await res.json();
    var thirdLength = responseJson.length;
    if(thirdLength === 0){
      confirmAlert({
        title: 'Confirm delete',
        message: 'Are you sure you want to delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              const link = "https://backend.amaderservice.com/api/servicetype?servicetypeID=" + id;
              var res = await fetch(link);
              var responseJson = res.json()
              if(responseJson){
                modalHide();
              }else{
                alert("Failed!")
              }  
            }
          },
          {
            label: 'No',
            onClick: () => console.log('Click No')
          }
        ]
      });         
    }else{
      alert("Cannot delete this Sub Category as it is not empty")
    }
  }


  const deleteThird = async (id) => {
    var res = await fetch("https://backend.amaderservice.com/api/finalServiceList?serviceid=" + id);
    var responseJson = await res.json();
    var l = responseJson.length;
    if(l === 0){
      confirmAlert({
        title: 'Confirm delete',
        message: 'Are you sure you want to delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              const link = "https://backend.amaderservice.com/api/service?sid=" + id;
              var res = await fetch(link);
              var responseJson = res.json()
              if(responseJson){
                modalHide();
              }else{
                alert("Failed!")
              }  
            }
          },
          {
            label: 'No',
            onClick: () => console.log('Click No')
          }
        ]
      });         
    }else{
      alert("Cannot delete this Service as it is not empty")
    }
  }


  const deleteFourth = async (id) => {
    
      confirmAlert({
        title: 'Confirm delete',
        message: 'Are you sure you want to delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              const link = "https://backend.amaderservice.com/api/deletefinalservice?fsid=" + id;
              var res = await fetch(link);
              var responseJson = res.json()
              if(responseJson){
                modalHide();
              }else{
                alert("Failed!")
              }  
            }
          },
          {
            label: 'No',
            onClick: () => console.log('Click No')
          }
        ]
      }); 
  }


  return (
    <div>
      <Modal
        size="sm"
        show={modalShow}
        onHide={() => modalHide()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h2 className="text-success">

            Successful
          </h2>
          <h5>
            Deleted the Item successfully
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => modalHide()}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Row style={{ width: "100%", height: 50, backgroundColor: "#060b26", margin: 0, }}>
        <Col onClick={() => history.push('/services')} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>All Services</h4>
        </Col>
        <Col onClick={() => history.push("/servicesAdd")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Add Service</h4>
        </Col>
        <Col onClick={() => history.push("/subCategoryEdit")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Edit Sub Category</h4>
        </Col>
        <Col onClick={() => history.push("/servicesEdit")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Edit Service</h4>
        </Col>
        <Col style={{ cursor: "pointer", textAlign: "center", backgroundColor: "#ffffff20" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Delete Service</h4>
        </Col>
      </Row>



      <div style={{ margin: 20 }}>
        <h1 style={{ textAlign: "center", marginTop: 0, }}>Delete Service</h1>

        <Row style={{ textAlign: "center", paddingBottom: 0, marginBottom: -20 }}>
          <Col style={{ textAlign: "center", paddingBottom: 0, marginBottom: 0 }}>
            <div style={{ borderRadius: 5, margin: 20, marginBottom: 0, alignItems: "center", justifyContent: 'center', padding: 5, }}>
              <p style={{ margin: 0, padding: 0, fontSize: 18, fontWeight: "bold", color: "black" }}>Category ({first.length})</p>
            </div>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <div style={{ borderRadius: 5, margin: 20, alignItems: "center", justifyContent: 'center', padding: 5, }}>
              <p style={{ margin: 0, padding: 0, fontSize: 18, fontWeight: "bold", color: "black" }}>Sub Category ({second.length})</p>
            </div>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <div style={{ borderRadius: 5, margin: 20, alignItems: "center", justifyContent: 'center', padding: 5, }}>
              <p style={{ margin: 0, padding: 0, fontSize: 18, fontWeight: "bold", color: "black" }}>Service ({third.length})</p>
            </div>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <div style={{borderRadius: 5, margin: 20, alignItems: "center", justifyContent: 'center', padding: 5, }}>
              <p style={{ margin: 0, padding: 0, fontSize: 18, fontWeight: "bold", color: "black" }}>Sub Service ({fourth.length})</p>
            </div>
          </Col>
        </Row>




        <Row style={{ marginBottom: 100 }}>
          <Col style={{ textAlign: "center", }}>
            {first.map((item) => (
              <Row style={{ border: "1px solid #01535e50", cursor: "pointer", backgroundColor: item.id == firstId ? "#01535e" : "", color: item.id == firstId ? "white" : "black", marginLeft: 20, marginRight: 20, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
              <Col md={10} onClick={() => {
                fetch_Second(item.id)
                setFirstId(item.id)
                setThird([])
                setFourth([])

              }} >
                <p style={{ margin: 0, padding: 0 }} >{item.category_name} </p>
                </Col>

                <Col md={2} onClick={() => deleteFirst(item.id)}>
                <FaIcons.FaTrash />

                </Col>
              </Row>
            ))}
          </Col>

          <Col style={{ textAlign: "center" }}>
            {second.map((item) => (
              <Row style={{ border: "1px solid #2a345750", cursor: "pointer", backgroundColor: item.id == secondId ? "#2a3457" : "", color: item.id == secondId ? "white" : "black", marginLeft: 20, marginRight: 20, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
                <Col md={10} onClick={() => fetch_Third(item.id)}>
                  <p style={{ margin: 0, padding: 0 }}>{item.service_type_name} </p>

                </Col>
                <Col md={2} onClick={() => deleteSecond(item.id)}>
                <FaIcons.FaTrash />

                </Col>
              </Row>
            ))}
          </Col>

          <Col style={{ textAlign: "center" }}>
            {third.map((item) => (
              <Row style={{ border: "1px solid #761e6e50", cursor: "pointer", backgroundColor: item.id == thirdId ? "#761e6e" : "", color: item.id == thirdId ? "white" : "black", marginLeft: 20, marginRight: 20, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
              <Col md={10}  onClick={() => fetch_Fourth(item.id)} >
                <p style={{ margin: 0, padding: 0 }}>{item.service_name} </p>

                </Col>
              <Col md={2} onClick={() => deleteThird(item.id)}>
              <FaIcons.FaTrash />

              </Col>
            </Row>
            ))}
          </Col>

          <Col style={{ textAlign: "center" }}>
            {fourth.map((item) => (
              <Row style={{ border: "1px solid #3b430150", cursor: "pointer", backgroundColor: item.id == fourthId ? "#3b4301" : "", color: item.id == fourthId ? "white" : "black", marginLeft: 20, marginRight: 20, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
              <Col >
                <p style={{ margin: 0, padding: 0 }}>{item.service_name} </p>
                <p style={{ margin: 0, padding: 0 }}>৳ {item.price} </p>
                <p style={{ margin: 0, padding: 0 }}>{item.typecode} </p>

                </Col>
              <Col md={2} onClick={() => deleteFourth(item.id)}>
              <FaIcons.FaTrash />

              </Col>
            </Row>
            ))}
          </Col>

        </Row>
      </div>


    </div>
  );
};

export default AdminPendingOrder;
