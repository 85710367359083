import React, { useEffect, useState, } from "react";
import { useHistory } from "react-router-dom";
import "./PendingOrder.css"
import { Table, Row, Col, Modal, Button } from 'react-bootstrap';

const AdminPendingOrder = () => {
  const [allLocation, setAllLocation] = useState([]);

  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [zone, setZone] = useState([]);

  const [countryId, setCountryId] = useState(null);
  const [cityId, setCityId] = useState(null);

  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState();
  const [modalInput, setModalInput] = useState("");
  const [query, setQuery] = useState("");

  const [Allpage, setAllPage] = useState({
    currentPage: null,
    lastPage: null
  });


  const [page, setPage] = useState("Locations");

  useEffect(() => {
    fetchItem(1);
    fetchCountry();
  }, []);
  const history = useHistory();

  const fetchItem = async (page) => {
    fetch("https://backend.amaderservice.com/api/all_location?page=" + page)
      .then((response) => response.json())
      .then((data) => {
        setAllLocation(data.data.reverse())
        let obj = {
          currentPage: page,
          lastPage: data.last_page
        }
        setAllPage(obj)
      })
  };

  function searchHandler() {
    const link = "https://backend.amaderservice.com/api/"
    fetch(link + "searchallserviceadmin?search=" + query)
      .then((response) => response.json())
      .then((data) => {
        setAllLocation(data.data.reverse())
        let obj = {
          currentPage: 1,
          lastPage: data.last_page
        }
        setAllPage(obj)
      })

  }








  const fetchCountry = async () => {

    fetch("https://backend.amaderservice.com/api/showCountry")
      .then((response) => response.json())
      .then((data) => setCountry(data.reverse()))
  };

  const fetchCity = async (id) => {
    setCountryId(id)

    fetch("https://backend.amaderservice.com/api/city?countryid=" + id)
      .then((response) => response.json())
      .then((data) => setCity(data.reverse()))
  };

  const fetchZone = async (id) => {
    setCityId(id)

    fetch("https://backend.amaderservice.com/api/zonebycity?cityid=" + id)
      .then((response) => response.json())
      .then((data) => setZone(data.reverse()))
  };

  function openCityModal() {
    setModalType("Enter City Name");
    setModalShow(true);
  }

  function openZoneModal() {
    setModalType("Enter Zone Title");
    setModalShow(true);
  }

  const submit = async () => {
    if (modalType == "Enter Country Name") {
      const res = await fetch("https://backend.amaderservice.com/api/addCountry?countryname=" + modalInput);
      const responseJson = await res.json();
      if (responseJson.status == "success") {
        fetchCountry();
        setModalShow(false);
        setModalInput("");
        setModalType("");

      } else {
        alert("Failed")
      }
    } else if (modalType == "Enter City Name") {
      const res = await fetch("https://backend.amaderservice.com/api/addCity?cityname=" + modalInput + "&countryid=" + countryId);
      const responseJson = await res.json();

      if (responseJson.status == "success") {
        fetchCity(countryId);
        setModalShow(false);
        setModalInput("");
        setModalType("");

      }
      else {
        alert("Failed")
      }
    } else if (modalType == "Enter Zone Title") {
      const res = await fetch("https://backend.amaderservice.com/api/addZone?zonename=" + modalInput + "&cityid=" + cityId);
      const responseJson = await res.json();

      if (responseJson.status == "success") {
        fetchZone(cityId);
        setModalShow(false);
        setModalInput("");
        setModalType("");

      }
      else {
        alert("Failed")
      }
    } else {
      alert("Failed!")
    }


  };

  return (
    <div>
      <Row style={{ width: "100%", height: 50, backgroundColor: "#060b26", margin: 0, }}>
        <Col onClick={() => setPage("Locations")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "Locations" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>All Locations</h4>
        </Col>
        <Col onClick={() => setPage("addLocation")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: page == "addLocation" ? "#ffffff20" : "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Add Location</h4>
        </Col>
      </Row>
      {page == "Locations" &&
        <div style={{ margin: 20 }}>
{/* SEARCH AND PAGINATION START */}
<Row style={{ width: "100%", margin: 0, padding: 0 }}>
          <Col style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>
            <h4 style={{ padding: 0, color: "#060b26", textAlign: "left", }}>Locations</h4>

          </Col>

          <Col style={{ cursor: "pointer", textAlign: "right", padding: 0, marginTop: -5 }}>
            <form onSubmit={() => searchHandler()}>
              <input
                type="text"
                class="form-control"
                id=""
                style={{ margin: 0 }}
                placeholder="Search..."
                value={query}
                onChange={(event) => setQuery(event.target.value)}
              ></input>
            </form>

          </Col>

          <Col style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>
            <Row style={{ width: "100%", margin: 0, padding: 0 }}>

              <Col onClick={() => {
                let obj = {
                  currentPage: Allpage.currentPage - 1,
                  lastPage: Allpage.lastPage
                }

                if (Allpage.currentPage !== 1) {

                  setAllPage(obj)
                  fetchItem(Allpage.currentPage - 1)

                }
              }} style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>

                <h4 className="paginationButton" style={{ padding: 0, textAlign: "right", }}>&#9664;</h4>
              </Col>

              <Col style={{ cursor: "pointer", textAlign: "center", padding: 0 }}>

                <h4 style={{ padding: 0, textAlign: "center", color: "#060b26" }}>Page {Allpage.currentPage} of {Allpage.lastPage}</h4>
              </Col>

              <Col onClick={() => {
                let obj = {
                  currentPage: Allpage.currentPage + 1,
                  lastPage: Allpage.lastPage
                }
                if (Allpage.currentPage !== Allpage.lastPage) {

                  setAllPage(obj)
                  fetchItem(Allpage.currentPage + 1)

                }
              }}
                style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>

                <h4
                  className="paginationButton" style={{ padding: 0, textAlign: "left" }}>&#9654;</h4>
              </Col>

            </Row>
          </Col>


        </Row>
        {/* SEARCH AND PAGINATION END */}              
        <Table responsive>
            <thead>
              <tr>
                <th>Country</th>
                <th>City</th>
                <th>Zone</th>

              </tr>
            </thead>
            <tbody>
              {allLocation.map((item) => (
                <tr>
                  <td >{item.country_name}</td>
                  <td >{item.city_name}</td>
                  <td >{item.zone}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      }

      {page == "addLocation" &&
        <div style={{ margin: 20 }}>
          <h1 style={{ textAlign: "center", marginTop: 0, }}>Add Location</h1>

          <Modal
            size="sm"
            show={modalShow}
            onHide={() => setModalShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <h5>

                {modalType}
              </h5>
              <input
                type="text"
                class="form-control"
                id=""
                style={{ marginBottom: 0, marginTop: 10 }}
                placeholder="Enter here..."
                value={modalInput}
                onChange={(event) => setModalInput(event.target.value)}
              ></input>


            </Modal.Body>
            <Modal.Footer>
              <Button style={{ width: "100%", marginTop: 0 }} onClick={() => submit()}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          <Row style={{ textAlign: "center", paddingBottom: 0, marginBottom: 0 }}>
            <Col style={{ textAlign: "center", paddingBottom: 0, marginBottom: 0 }}>
              <div style={{ borderRadius: 5, marginLeft: 20, marginRight: 20, marginBottom: 0, alignItems: "center", justifyContent: 'center', padding: 5, }}>
                <p style={{ margin: 0, padding: 0, fontSize: 18, fontWeight: "bold", color: "black" }}>Country ({country.length})</p>
              </div>
              <div onClick={() => {
                setModalType("Enter Country Name");
                setModalShow(true);
              }} style={{ cursor: "pointer", border: "1px solid #01535e50", marginLeft: 20, marginRight: 20, borderRadius: 5, marginBottom: 0, alignItems: "center", justifyContent: 'center', padding: 5, }}>
                <p style={{ margin: 0, padding: 0, color:"#01535e" }}>+ Add Country...</p>
              </div>
            </Col>
            <Col style={{ textAlign: "center" }}>
              <div style={{ borderRadius: 5,marginLeft: 20, marginRight: 20, alignItems: "center", justifyContent: 'center', padding: 5,}}>
                <p style={{ margin: 0, padding: 0, fontSize: 18,fontWeight: "bold",  color: "black" }}>City ({city.length})</p>
              </div>
              <div onClick={() => countryId === null ? alert("Please select a Country first!") : openCityModal()} 
                style={{ border: "1px solid #2a345750",cursor: "pointer", borderRadius: 5, marginLeft: 20, marginRight: 20, alignItems: "center", justifyContent: 'center', padding: 5,}}>
                <p style={{  margin: 0, padding: 0, color:"#2a3457" }}>+ Add City...</p>
              </div>
            </Col>
            <Col style={{ textAlign: "center" }}>
            <div style={{ cursor: "pointer", borderRadius: 5, marginLeft: 20, marginRight: 20, alignItems: "center", justifyContent: 'center', padding: 5,}}>
                <p style={{margin: 0, padding: 0, fontSize: 18,fontWeight: "bold",  color: "black" }}>Zone ({zone.length})</p>
              </div>
              <div onClick={() => cityId === null ? alert("Please select a City first!") : openZoneModal()} style={{ border: "1px solid #761e6e50",cursor: "pointer", borderRadius: 5, marginLeft: 20, marginRight: 20, alignItems: "center", justifyContent: 'center', padding: 5,}}>
                <p style={{  margin: 0, padding: 0, color:"#761e6e" }}>+ Add Zone...</p>
              </div>
            </Col>
          </Row>




          <Row>
            <Col style={{ textAlign: "center", }}>
              {country.map((item) => (
                <div onClick={() => {
                  fetchCity(item.id)
                  setZone([])

                }} style={{ border: "1px solid #01535e50", cursor: "pointer", backgroundColor: item.id == countryId ? "#01535e" : "", color: item.id == countryId ? "white" : "black", marginLeft: 20, marginRight: 20, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
                  <p style={{ margin: 0, padding: 0 }} >{item.country_name} </p>

                </div>
              ))}
            </Col>

            <Col style={{ textAlign: "center" }}>
              {city.map((item) => (
                <div onClick={() => fetchZone(item.id)} style={{ border: "1px solid #2a345750", cursor: "pointer", backgroundColor: item.id == cityId ? "#2a3457" : "", color: item.id == cityId ? "white" : "black", marginLeft: 20, marginRight: 20, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
                  <p style={{ margin: 0, padding: 0 }}>{item.city_name} </p>

                </div>
              ))}
            </Col>

            <Col style={{ textAlign: "center" }}>
              {zone.map((item) => (
                <div style={{ border: "1px solid #761e6e50", cursor: "pointer", backgroundColor: item.id == cityId ? "#761e6e" : "", color: item.id == cityId ? "white" : "black", marginLeft: 20, marginRight: 20, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
                  <p style={{ margin: 0, padding: 0 }}>{item.zone} </p>

                </div>
              ))}
            </Col>

          </Row>
        </div>
      }
    </div>
  );
};

export default AdminPendingOrder;
