import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./PendingOrder.css"
import { Table, Row, Col, Modal, Button } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import dataFormat from './secondLayerDetailsFormat';
import axios, { post } from 'axios';
import noImage from './../asset/noImage.jpg';

const AdminPendingOrder = () => {


  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);

  const [firstId, setFirstId] = useState(null);
  const [secondId, setSecondId] = useState(null);

  const [miniArr, setMiniArr] = useState([{ mini: "" }]);
  const [includedArr, setIncludedArr] = useState([{ includeHeading: "" }]);
  const [excludedArr, setExcludedArr] = useState([{ excludeHeading: "" }]);
  const [SFArr, setSFArr] = useState([{ featuresHeading: "" }]); //Service Feature Array
  const [FAQArr, setFAQArr] = useState([{ answer: "", question: "" }]); //faq Array
  const [TACArr, setTACArr] = useState([{ termsHeading: "", termsDetails: "" }]); //tac Array
  const [chooseArr, setChooseArr] = useState([{ chooseHeading: "", chooseDetails: "" }]); //choose Array
  const [nightShifting, setNightShifting] = useState("");
  const [details, setDetails] = useState("");
  const [pricing, setPricing] = useState("");
  const [payment, setPayment] = useState("");
  const [thirdHeading, setThirdHeading] = useState("");
  const [iconfile, setIconfile] = useState("");
  const [coverfile, setCoverfile] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [existingCover, setExistingCover] = useState("");
  const [existingIcon, setExistingIcon] = useState("");





  useEffect(() => {
    fetch_First()
  }, []);
  const history = useHistory();

  const fetch_First = async () => {
    fetch("https://backend.amaderservice.com/api/showservice")
      .then((response) => response.json())
      .then((data) => setFirst(data.reverse()))
  };

  const fetch_Second = async (id) => {

    setFirstId(id)
    fetch("https://backend.amaderservice.com/api/subcatagory?maincatagoryid=" + id)
      .then((response) => response.json())
      .then((data) => setSecond(data.reverse()))
  };

  //---------------------------------------------------------------------------------------------- MINI START
  const handleMini = (e, index) => {
    const { name, value } = e.target;
    const list = [...miniArr];
    list[index][name] = value;
    setMiniArr(list);
  };
  const removeMini = index => {
    const list = [...miniArr];
    list.splice(index, 1);
    setMiniArr(list);
  };
  const addMini = () => {
    setMiniArr([...miniArr, { mini: "" }]);
  };

  //---------------------------------------------------------------------------------------------- INCLUDED START
  const handleIncluded = (e, index) => {
    const { name, value } = e.target;
    const list = [...includedArr];
    list[index][name] = value;
    setIncludedArr(list);
  };
  const removeIncluded = index => {
    const list = [...includedArr];
    list.splice(index, 1);
    setIncludedArr(list);
  };
  const addIncluded = () => {
    setIncludedArr([...includedArr, { includeHeading: "" }]);
  };
  //------------------------------------------------------------------------------------------------- EXCLUDED START

  const handleExcluded = (e, index) => {
    const { name, value } = e.target;
    const list = [...excludedArr];
    list[index][name] = value;
    setExcludedArr(list);
  };
  const removeExcluded = index => {
    const list = [...excludedArr];
    list.splice(index, 1);
    setExcludedArr(list);
  };
  const addExcluded = () => {
    setExcludedArr([...excludedArr, { excludeHeading: "" }]);
  };
  //------------------------------------------------------------------------------------------------- S FEATURES START
  const handleSF = (e, index) => {
    const { name, value } = e.target;
    const list = [...SFArr];
    list[index][name] = value;
    setSFArr(list);
  };
  const removeSF = index => {
    const list = [...SFArr];
    list.splice(index, 1);
    setSFArr(list);
  };
  const addSF = () => {
    setSFArr([...SFArr, { featuresHeading: "" }]);
  };

  //------------------------------------------------------------------------------------------------- FAQ START
  const handleFAQ = (e, index) => {
    const { name, value } = e.target;
    const list = [...FAQArr];
    list[index][name] = value;
    setFAQArr(list);
  };
  const removeFAQ = index => {
    const list = [...FAQArr];
    list.splice(index, 1);
    setFAQArr(list);
  };
  const addFAQ = () => {
    setFAQArr([...FAQArr, { answer: "", question: "" }]);
  };


  //------------------------------------------------------------------------------------------------- TAC START
  const handleTAC = (e, index) => {
    const { name, value } = e.target;
    const list = [...TACArr];
    list[index][name] = value;
    setTACArr(list);
  };
  const removeTAC = index => {
    const list = [...TACArr];
    list.splice(index, 1);
    setTACArr(list);
  };
  const addTAC = () => {
    setTACArr([...TACArr, { termsHeading: "", termsDetails: "" }]);
  };


  //------------------------------------------------------------------------------------------------- CHOOSE START
  const handleChoose = (e, index) => {
    const { name, value } = e.target;
    const list = [...chooseArr];
    list[index][name] = value;
    setChooseArr(list);
  };
  const removeChoose = index => {
    const list = [...chooseArr];
    list.splice(index, 1);
    setChooseArr(list);
  };
  const addChoose = () => {
    setChooseArr([...chooseArr, { chooseHeading: "", chooseDetails: "" }]);
  };

  const fetchSecondDetails = async (id) => {
    setSecondId(id)
    const data = await fetch(`https://backend.amaderservice.com/api/getServiceDetailsadmin?servicetypeID=` + id);
    const item = await data.json();

    if (item.details) {
      setMiniArr(item.minidetails.length > 0 ? item.minidetails : dataFormat.minidetails)
      setIncludedArr(item.includeHeading.length > 0 ? item.includeHeading : dataFormat.includeHeading)
      setExcludedArr(item.excludeHeading.length > 0 ? item.excludeHeading : dataFormat.excludeHeading)
      setSFArr(item.servicefeatures.length > 0 ? item.servicefeatures : dataFormat.servicefeatures)
      setFAQArr(item.faq.length > 0 ? item.faq : dataFormat.faq)
      setTACArr(item.tandc.length > 0 ? item.tandc : dataFormat.tandc)
      setChooseArr(item.choose.length > 0 ? item.choose : dataFormat.choose)
      setNightShifting(item.nightshifting ? item.nightshifting : dataFormat.nightshifting)
      setPricing(item.pricing ? item.pricing : dataFormat.pricing)
      setPayment(item.payment ? item.payment : dataFormat.payment)
      setDetails(item.details ? item.details : dataFormat.details)
      setThirdHeading(item.thirdheading ? item.thirdheading : dataFormat.thirdheading)
      setExistingCover(item.bannerimage)
      setExistingIcon(item.iconimage)


    } else {
      setMiniArr(dataFormat.minidetails)
      setIncludedArr(dataFormat.includeHeading)
      setExcludedArr(dataFormat.excludeHeading)
      setSFArr(dataFormat.servicefeatures)
      setFAQArr(dataFormat.faq)
      setTACArr(dataFormat.tandc)
      setChooseArr(dataFormat.choose)
      setNightShifting(dataFormat.nightshifting)
      setPricing(dataFormat.pricing)
      setPayment(dataFormat.payment)
      setDetails(dataFormat.details)
      setThirdHeading(dataFormat.thirdheading)
      setExistingCover(item.noImage)
      setExistingIcon(item.noImage)
    }






  };

  //----------------------------------------------------------------------------------------------------------SUBMIT FORM STARTS

  async function submit() {

    const url = "https://backend.amaderservice.com/api/addservicedetails";
    var p = await post(url,
      {
        serviceId: secondId,
        iconfile: iconfile,
        coverfile: coverfile,
        includeHeading: includedArr,
        faq: FAQArr,
        excludeHeading: excludedArr,
        details: details,
        pricing: pricing,
        payment: payment,
        nightshifting: nightShifting,
        tandc: TACArr,
        choose: chooseArr,
        servicefeatures: SFArr,
        minidetails: miniArr,
        thirdheading: thirdHeading
      })

    //var p = await a.text();  


    if (p) {
      setModalShow(true)

    } else {
      alert("Error!")
    }

  }

  function modalHide() {
    setSecondId(null);
    setMiniArr([{ mini: "" }]);
    setIncludedArr([{ includeHeading: "" }]);
    setExcludedArr([{ excludeHeading: "" }]);
    setSFArr([{ featuresHeading: "" }]); //Service Feature Array
    setFAQArr([{ answer: "", question: "" }]); //faq Array
    setTACArr([{ termsHeading: "", termsDetails: "" }]); //tac Array
    setChooseArr([{ chooseHeading: "", chooseDetails: "" }]); //choose Array
    setNightShifting("");
    setDetails("");
    setPricing("");
    setPayment("");
    setThirdHeading("");
    setIconfile("");
    setCoverfile("");
    setModalShow(false);
  }

  const uploadIcon = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
      return;
    createIcon(files[0]);
  }
  const createIcon = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setIconfile(e.target.result);
    };

    reader.readAsDataURL(file);
  }

  const uploadCover = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
      return;
    createCover(files[0]);
  }
  const createCover = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setCoverfile(e.target.result);
    };

    reader.readAsDataURL(file);
  }

  return (
    <div>
      <Modal
        size="sm"
        show={modalShow}
        onHide={() => modalHide()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h2 className="text-success">

            Successful
          </h2>
          <h5>

            Details updated
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => modalHide()}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Row style={{ width: "100%", height: 50, backgroundColor: "#060b26", margin: 0, }}>
        <Col onClick={() => history.push('/services')} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>All Services</h4>
        </Col>
        <Col onClick={() => history.push("/servicesAdd")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Add Service</h4>
        </Col>
        <Col style={{ cursor: "pointer", textAlign: "center", backgroundColor: "#ffffff20" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Edit Sub Category</h4>
        </Col>
        <Col onClick={() => history.push("/servicesEdit")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Edit Service</h4>
        </Col>
        <Col onClick={() => history.push("/servicesDelete")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Delete Service</h4>
        </Col>
      </Row>


      <div style={{ margin: 20 }}>
        <h1 style={{ textAlign: "center", marginTop: 0, }}>Edit Sub Category</h1>

        <Row style={{ textAlign: "center", paddingBottom: 0, marginBottom: -20 }}>
          <Col md={6} style={{ textAlign: "center", paddingBottom: 0, marginBottom: 0 }}>
            <div style={{ cursor: "pointer", alignItems: "center", justifyContent: 'center', padding: 5, margin: 20, borderRadius: 5 }}>
              <p style={{ margin: 0, padding: 0, fontSize: 18, fontWeight: "bold", color: "black" }}>Category ({first.length})</p>
            </div>
          </Col>
          <Col md={6} style={{ textAlign: "center", paddingBottom: 0, marginBottom: 0 }}>
            <div style={{ cursor: "pointer", alignItems: "center", justifyContent: 'center', padding: 5, margin: 20, borderRadius: 5 }}>
              <p style={{ margin: 0, padding: 0, fontSize: 18, fontWeight: "bold", color: "black" }}>Sub Category ({second.length})</p>
            </div>
          </Col>

        </Row>




        <Row>
          <Col md={6} style={{ textAlign: "center", }}>
            {first.map((item) => (
              <div onClick={() => {
                fetch_Second(item.id)
                setFirstId(item.id)

              }} style={{ border: "1px solid #2a345750", cursor: "pointer", backgroundColor: item.id == firstId ? "#01535e" : "", color: item.id == firstId ? "white" : "black", marginLeft: 20, marginRight: 20, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
                <p style={{ margin: 0, padding: 0 }} >{item.category_name} </p>

              </div>
            ))}
          </Col>

          <Col md={6} style={{ textAlign: "center" }}>
            {second.map((item) => (
              <div onClick={() => fetchSecondDetails(item.id)} style={{ border: "1px solid #2a345750", cursor: "pointer", backgroundColor: item.id == secondId ? "#2a3457" : "", color: item.id == secondId ? "white" : "black", marginLeft: 20, marginRight: 20, marginBottom: 0, borderRadius: 5, alignItems: "center", justifyContent: 'center', padding: 5 }}>
                <p style={{ margin: 0, padding: 0 }}>{item.service_type_name} </p>

              </div>
            ))}
          </Col>

          <Col md={12} style={{ textAlign: "center", marginTop: 20 }}>
            <div style={{ backgroundColor: "#123245", color: "white", margin: -20, marginTop: 0, borderRadius: 0, alignItems: "center", justifyContent: 'center', padding: 5 }}>
              <div style={{
                cursor: "pointer", borderRadius: 0, alignItems: "center",
                justifyContent: 'center', padding: 5, backgroundColor: "#123245"
              }}>
                <p style={{ margin: 0, padding: 0, fontSize: 30, fontWeight: "bold", color: "white" }}>Add Details to Sub Category</p>
              </div>

              {/**------------------------------------------------------------------------------ PHOTO START */}
              <div style={{ marginTop: "10px", margin: 20 }}>
                <h4 style={{ color: "white" }}>
                  Choose Images
                </h4>
                <Row>
                  <Col>
                    <img src={existingCover} alt="John" onError={() => setExistingCover(noImage)} style={{ height: 300, width: "auto", }} />
                    <p style={{ padding: 0, marginBottom: 10, color: "white", textAlign: "center" }}>Current Cover Image</p>

                    <input
                      style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey", width: 300, alignSelf: "center", margin: "auto" }}
                      className="form-control"
                      type="file"
                      name="coverfile"
                      placeholder="Cover File"
                      onChange={uploadCover} />

                  </Col>

                  <Col style={{ margin: "auto" }}>
                    <img src={existingIcon} alt="John" onError={() => setExistingIcon(noImage)} style={{ height: 100, width: "auto", }} />
                    <p style={{ padding: 0, marginBottom: 10, color: "white", textAlign: "center" }}>Current Icon Image</p>

                    <input
                      style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey", width: 300, alignSelf: "center", margin: "auto" }}
                      className="form-control"
                      type="file"
                      name="coverfile"
                      placeholder="Cover File"
                      onChange={uploadIcon} />

                  </Col>

                </Row>





              </div>



              {/**------------------------------------------------------------------------------ MINI START */}
              <div style={{ width: "100%", backgroundColor: "", height: 1, marginBottom: 20 }} />
              <div style={{ marginTop: "10px", marginBottom: 20 }}>
                <h4 style={{ color: "white" }}>
                  Enter Mini Details
                </h4>
                {miniArr.map((x, i) => {
                  return (
                    <Row style={{ margin: 10 }}>
                      <Col md={10} style={{ textAlign: "center" }}>
                        <input
                          style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                          name="mini"
                          className="form-control"

                          placeholder="Enter a mini detail"
                          value={x.mini}
                          onChange={e => handleMini(e, i)}
                        />
                      </Col>

                      <Col md={1} style={{ textAlign: "center", alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                        {miniArr.length - 1 === i &&
                          <FaIcons.FaPlusCircle style={{ fontSize: 25, cursor: "pointer" }} onClick={() => addMini()} />
                        }
                      </Col>

                      <Col md={1} style={{ textAlign: "center", }}>
                        {miniArr.length !== 1 &&
                          <FaIcons.FaMinusCircle style={{ fontSize: 25, cursor: "pointer" }} onClick={() => removeMini(i)} />}
                      </Col>

                    </Row>
                  );
                })}
              </div>



              {/**------------------------------------------------------------------------------ INCLUDED START*/}
              <div style={{ width: "100%", backgroundColor: "", height: 1, marginBottom: 20 }} />
              <div style={{ marginTop: "10px", marginBottom: 20 }}>
                <h4 style={{ color: "white" }}>
                  What's Included?
                </h4>
                {includedArr.map((x, i) => {
                  return (
                    <Row style={{ margin: 10 }}>
                      <Col md={10} style={{ textAlign: "center" }}>
                        <input
                          style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                          name="includeHeading"
                          className="form-control"
                          placeholder="Enter what's included"
                          value={x.includeHeading}
                          onChange={e => handleIncluded(e, i)}
                        />
                      </Col>

                      <Col md={1} style={{ textAlign: "center", alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                        {includedArr.length - 1 === i &&
                          <FaIcons.FaPlusCircle style={{ fontSize: 25, cursor: "pointer" }} onClick={() => addIncluded()} />
                        }
                      </Col>

                      <Col md={1} style={{ textAlign: "center", }}>
                        {includedArr.length !== 1 &&
                          <FaIcons.FaMinusCircle style={{ fontSize: 25, cursor: "pointer" }} onClick={() => removeIncluded(i)} />}
                      </Col>

                    </Row>
                  );
                })}
              </div>





              {/**------------------------------------------------------------------------------ EXCLUDED START*/}
              <div style={{ width: "100%", backgroundColor: "", height: 1, marginBottom: 20 }} />
              <div style={{ marginTop: "10px", marginBottom: 20 }}>
                <h4 style={{ color: "white" }}>
                  What's Excluded?
                </h4>
                {excludedArr.map((x, i) => {
                  return (
                    <Row style={{ margin: 10 }}>
                      <Col md={10} style={{ textAlign: "center" }}>
                        <input
                          style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                          name="excludeHeading"
                          className="form-control"
                          placeholder="Enter what's excluded"
                          value={x.excludeHeading}
                          onChange={e => handleExcluded(e, i)}
                        />
                      </Col>

                      <Col md={1} style={{ textAlign: "center", alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                        {excludedArr.length - 1 === i &&
                          <FaIcons.FaPlusCircle style={{ fontSize: 25, cursor: "pointer" }} onClick={() => addExcluded()} />
                        }
                      </Col>

                      <Col md={1} style={{ textAlign: "center", }}>
                        {excludedArr.length !== 1 &&
                          <FaIcons.FaMinusCircle style={{ fontSize: 25, cursor: "pointer" }} onClick={() => removeExcluded(i)} />}
                      </Col>

                    </Row>
                  );
                })}
              </div>



              {/**------------------------------------------------------------------------------ S FEATURES START*/}
              <div style={{ width: "100%", backgroundColor: "", height: 1, marginBottom: 20 }} />
              <div style={{ marginTop: "10px", marginBottom: 20 }}>
                <h4 style={{ color: "white" }}>
                  What are the Service Features?
                </h4>
                {SFArr.map((x, i) => {
                  return (
                    <Row style={{ margin: 10 }}>
                      <Col md={10} style={{ textAlign: "center" }}>
                        <input
                          style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                          name="featuresHeading"
                          className="form-control"
                          placeholder="Enter a service feature"
                          value={x.featuresHeading}
                          onChange={e => handleSF(e, i)}
                        />
                      </Col>

                      <Col md={1} style={{ textAlign: "center", alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                        {SFArr.length - 1 === i &&
                          <FaIcons.FaPlusCircle style={{ fontSize: 25, cursor: "pointer" }} onClick={() => addSF()} />
                        }
                      </Col>

                      <Col md={1} style={{ textAlign: "center", }}>
                        {SFArr.length !== 1 &&
                          <FaIcons.FaMinusCircle style={{ fontSize: 25, cursor: "pointer" }} onClick={() => removeSF(i)} />}
                      </Col>

                    </Row>
                  );
                })}
              </div>


              {/**------------------------------------------------------------------------------ FAQ START*/}
              <div style={{ width: "100%", backgroundColor: "", height: 1, marginBottom: 20 }} />
              <div style={{ marginTop: "10px", marginBottom: 20 }}>
                <h4 style={{ color: "white" }}>
                  What are the FAQs?
                </h4>
                {FAQArr.map((x, i) => {
                  return (
                    <Row style={{ margin: 10 }}>
                      <Col md={10} style={{ textAlign: "center", marginBottom: 20 }}>
                        <p style={{ padding: 0, margin: 0, color: "grey", textAlign: "left" }}>Question</p>
                        <input
                          style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                          name="question"
                          className="form-control"
                          placeholder="Enter Question"
                          value={x.question}
                          onChange={e => handleFAQ(e, i)}
                        />
                        <p style={{ padding: 0, margin: 0, color: "grey", textAlign: "left" }}>Answer</p>
                        <input
                          style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                          className="form-control"
                          name="answer"
                          placeholder="Enter Answer"
                          value={x.answer}
                          onChange={e => handleFAQ(e, i)}
                        />

                      </Col>

                      <Col md={1} style={{ textAlign: "center", alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                        {FAQArr.length - 1 === i &&
                          <FaIcons.FaPlusCircle style={{ fontSize: 25, cursor: "pointer" }} onClick={() => addFAQ()} />
                        }
                      </Col>

                      <Col md={1} style={{ textAlign: "center", textAlign: "center", alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                        {FAQArr.length !== 1 &&
                          <FaIcons.FaMinusCircle style={{ fontSize: 25, cursor: "pointer" }} onClick={() => removeFAQ(i)} />}
                      </Col>

                    </Row>
                  );
                })}
              </div>



              {/**------------------------------------------------------------------------------ TAC START*/}
              <div style={{ width: "100%", backgroundColor: "", height: 1, marginBottom: 20 }} />
              <div style={{ marginTop: "10px", marginBottom: 20 }}>
                <h4 style={{ color: "white" }}>
                  What are the Terms and Conditions?
                </h4>
                {TACArr.map((x, i) => {
                  return (
                    <Row style={{ margin: 10 }}>
                      <Col md={10} style={{ textAlign: "center", marginBottom: 20 }}>
                        <p style={{ padding: 0, margin: 0, color: "grey", textAlign: "left" }}>Heading</p>
                        <input
                          style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                          name="termsHeading"
                          className="form-control"
                          placeholder="Enter Heading"
                          value={x.termsHeading}
                          onChange={e => handleTAC(e, i)}
                        />
                        <p style={{ padding: 0, margin: 0, color: "grey", textAlign: "left" }}>Details</p>
                        <input
                          style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                          className="form-control"
                          name="termsDetails"
                          placeholder="Enter Details"
                          value={x.termsDetails}
                          onChange={e => handleTAC(e, i)}
                        />

                      </Col>

                      <Col md={1} style={{ textAlign: "center", alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                        {TACArr.length - 1 === i &&
                          <FaIcons.FaPlusCircle style={{ fontSize: 25, cursor: "pointer" }} onClick={() => addTAC()} />
                        }
                      </Col>

                      <Col md={1} style={{ textAlign: "center", textAlign: "center", alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                        {TACArr.length !== 1 &&
                          <FaIcons.FaMinusCircle style={{ fontSize: 25, cursor: "pointer" }} onClick={() => removeTAC(i)} />}
                      </Col>

                    </Row>
                  );
                })}
              </div>


              {/**------------------------------------------------------------------------------ CHOOSE START*/}
              <div style={{ width: "100%", backgroundColor: "", height: 1, marginBottom: 20 }} />
              <div style={{ marginTop: "10px", marginBottom: 20 }}>
                <h4 style={{ color: "white" }}>
                  Why choose us?
                </h4>
                {chooseArr.map((x, i) => {
                  return (
                    <Row style={{ margin: 10 }}>
                      <Col md={10} style={{ textAlign: "center", marginBottom: 20 }}>
                        <p style={{ padding: 0, margin: 0, color: "grey", textAlign: "left" }}>Heading</p>
                        <input
                          style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                          name="chooseHeading"
                          className="form-control"
                          placeholder="Enter Heading"
                          value={x.chooseHeading}
                          onChange={e => handleChoose(e, i)}
                        />
                        <p style={{ padding: 0, margin: 0, color: "grey", textAlign: "left" }}>Details</p>
                        <input
                          style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                          className="form-control"
                          name="chooseDetails"
                          placeholder="Enter Details"
                          value={x.chooseDetails}
                          onChange={e => handleChoose(e, i)}
                        />

                      </Col>

                      <Col md={1} style={{ textAlign: "center", alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                        {chooseArr.length - 1 === i &&
                          <FaIcons.FaPlusCircle style={{ fontSize: 25, cursor: "pointer" }} onClick={() => addChoose()} />
                        }
                      </Col>

                      <Col md={1} style={{ textAlign: "center", textAlign: "center", alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                        {chooseArr.length !== 1 &&
                          <FaIcons.FaMinusCircle style={{ fontSize: 25, cursor: "pointer" }} onClick={() => removeChoose(i)} />}
                      </Col>

                    </Row>
                  );
                })}
              </div>


              {/**------------------------------------------------------------------------------ NIGHT SHIFT START*/}
              <div style={{ width: "100%", backgroundColor: "", height: 1, marginBottom: 20 }} />
              <div style={{ marginTop: "10px", marginBottom: 20 }}>
                <h4 style={{ color: "white" }}>
                  Night Shifting
                </h4>

                <Row style={{ margin: 10 }}>
                  <Col md={12} style={{ textAlign: "center", marginBottom: 20 }}>
                    <textarea
                      style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                      name="chooseHeading"
                      className="form-control"
                      placeholder="Add Details"
                      value={nightShifting}
                      onChange={(event) => setNightShifting(event.target.value)}
                    />

                  </Col>

                </Row>
              </div>

              {/**------------------------------------------------------------------------------ PAYMENT START*/}
              <div style={{ width: "100%", backgroundColor: "", height: 1, marginBottom: 20 }} />
              <div style={{ marginTop: "10px", marginBottom: 20 }}>
                <h4 style={{ color: "white" }}>
                  Payment
                </h4>

                <Row style={{ margin: 10 }}>
                  <Col md={12} style={{ textAlign: "center", marginBottom: 20 }}>
                    <textarea
                      style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                      name="payment"
                      className="form-control"
                      placeholder="Add Details"
                      value={payment}
                      onChange={(event) => setPayment(event.target.value)}
                    />

                  </Col>

                </Row>
              </div>


              {/**------------------------------------------------------------------------------ PRICING START*/}
              <div style={{ width: "100%", backgroundColor: "", height: 1, marginBottom: 20 }} />
              <div style={{ marginTop: "10px", marginBottom: 20 }}>
                <h4 style={{ color: "white" }}>
                  Pricing
                </h4>

                <Row style={{ margin: 10 }}>
                  <Col md={12} style={{ textAlign: "center", marginBottom: 20 }}>
                    <textarea
                      style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                      name="pricing"
                      className="form-control"
                      placeholder="Add Details"
                      value={pricing}
                      onChange={(event) => setPricing(event.target.value)}
                    />

                  </Col>

                </Row>
              </div>


              {/**------------------------------------------------------------------------------ THIRD HEADING START*/}
              <div style={{ width: "100%", backgroundColor: "", height: 1, marginBottom: 20 }} />
              <div style={{ marginTop: "10px", marginBottom: 20 }}>
                <h4 style={{ color: "white" }}>
                  Service Heading
                </h4>

                <Row style={{ margin: 10 }}>
                  <Col md={12} style={{ textAlign: "center", marginBottom: 20 }}>
                    <textarea
                      style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey" }}
                      name="thirdHeading"
                      className="form-control"
                      placeholder="Add Details"
                      value={thirdHeading}
                      onChange={(event) => setThirdHeading(event.target.value)}
                    />

                  </Col>

                </Row>
              </div>

              {/**------------------------------------------------------------------------------ DETAILS START*/}
              <div style={{ width: "100%", backgroundColor: "", height: 1, marginBottom: 20 }} />
              <div style={{ marginTop: "10px", marginBottom: 20 }}>
                <h4 style={{ color: "white" }}>
                  Details
                </h4>

                <Row style={{ margin: 10 }}>
                  <Col md={12} style={{ textAlign: "center", marginBottom: 20 }}>
                    <textarea

                      style={{ backgroundColor: "transparent", color: "white", border: "1px solid grey", height: 150 }}
                      name="details"
                      className="form-control"
                      placeholder="Add Details"
                      value={details}
                      onChange={(event) => setDetails(event.target.value)}
                    />

                  </Col>

                </Row>
              </div>

              <Button style={{ width: "50%", marginBottom: 100 }} onClick={() => { (details.length > 0) ? submit() : alert("Please enter Details Field") }}>Submit</Button>





            </div>

          </Col>

        </Row>
      </div>


    </div>
  );
};

export default AdminPendingOrder;
