const data = {
    primaryinfo: [
        {
            id: null,
            vendor_name: null,
            address: null,
            email: null,
            contact: null,
            trade_license: null
        }
    ],
    paymetdetail: [
        {
            
            bank_name: null,
            account_number: null,
            rocket_number: null,
            bkash_number: null,
            deposit: 0,
            bank_branch: null,
            bank_account_name: null,
            bank_routing_number: null,
            bank_account_type: null,
            nagad_number: null
        }
    ],
    given_service: [
        {
            id: null,
            name: null,
            email: null,
            address: null,
            contact_number: null,
            contact_person: null,
            contact_person_number: null,
            order_address: null,
            service: null,
            service_name: null,
            price: null,
            quantity: null,
            status: null,
            orderid: null,
            expectedTime: null,
            reschedule_time: null,
            zone: null,
            mainorderid: null,
            coupon_discount_amount: 0,
            sp_name: null,
            sp_number: null,
            photo: null,
            area: null,
            isDigital: null
        }
    ],
    pending_service: [
        {
            id: null,
            name: null,
            email: null,
            address: null,
            contact_number: null,
            contact_person: null,
            contact_person_number: null,
            order_address: null,
            service: null,
            service_name: null,
            price: null,
            quantity: null,
            status: null,
            orderid: null,
            expectedTime: null,
            reschedule_time: null,
            zone: null,
            mainorderid: null,
            coupon_discount_amount: 0,
            sp_name: null,
            sp_number: null,
            photo: null,
            area: null,
            isDigital: null
        }
    ],
    canceled_service: [
        {
            id: null,
            name: null,
            email: null,
            address: null,
            contact_number: null,
            contact_person: null,
            contact_person_number: null,
            order_address: null,
            service: null,
            service_name: null,
            price: null,
            quantity: null,
            status: null,
            orderid: null,
            expectedTime: null,
            reschedule_time: null,
            zone: null,
            mainorderid: null,
            coupon_discount_amount: 0,
            sp_name: null,
            sp_number: null,
            photo: null,
            area: null,
            isDigital: null
        }
    ]
}

export default data;