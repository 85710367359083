import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './Navbar.css';
import { IconContext } from 'react-icons';
import cartEmptyImage from "./../asset/logo.png";
import { Table, Row, Col, Button } from 'react-bootstrap';
import { reactLocalStorage } from "reactjs-localstorage";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "react-router-dom";

function Navbar() {  
  function signOut() {
    confirmAlert({
      title: "Are you sure?",
      message: "Would you like to sign out of your Admin account?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await reactLocalStorage.set("isLoggedIn", false);
            history.push("/login");
            window.location.reload();
          }
        },
        {
          label: "No"
        }
      ]
    });

  }
  const [sidebar, setSidebar] = useState(false);
  const history = useHistory();

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar'>
        <Link to='#' className='menu-bars'>

        <div onClick={showSidebar}  style={{width:60, height:40, backgroundColor:"", display:"flex", alignItems:"center", justifyContent: 'center',}}>
            <FaIcons.FaBars />
          </div>
          </Link>


          <div style={{marginRight:20, cursor:"pointer"}} onClick={()=>signOut()}>          
               <img src={cartEmptyImage} alt="John" style={{ height: 30, width: 30, borderRadius: 15,  }} /> 
          </div>
          
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
                <div style={{width:40, height:40, backgroundColor:"#ffffff20", display:"flex", alignItems:"center", justifyContent: 'center',}}>
                <FaIcons.FaChevronLeft/>

                </div>

              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>


      </IconContext.Provider>
    </>
  );
}

export default Navbar;
