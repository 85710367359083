import React, { useEffect, useState } from "react";
import './App.css';
import Navbar from './components/Navbar';
import { useHistory } from "react-router-dom";

import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './pages/Services';
import ServicesAdd from './pages/ServicesAdd';
import ServicesEdit from './pages/ServicesEdit';
import SubCategoryEdit from './pages/SubCategoryEdit';

import ServicesDelete from './pages/ServicesDelete';

import Locations from './pages/Locations';
import Dashboard from "./pages/Dashboard";
import CreateOrder from "./pages/CreateOrder";
import Wallet from "./pages/Wallet";
import Coupon from "./pages/Coupon";
import Login from "./pages/Login";
import { reactLocalStorage } from "reactjs-localstorage";
import AddMainService from "./pages/AddMainService";
import RegUsers from "./pages/RegUsers";
import RegVendors from "./pages/RegVendors";
import RegVendorDetails from "./pages/RegVendorDetails";
import RegUserDetails from "./pages/RegUserDetails";

import Orders from "./pages/Orders";

import AssignedOrderDetails from "./pages/AssignedOrderDetails";
import CompletedOrderDetails from "./pages/CompletedOrderDetails";
import CancelledOrderDetails from "./pages/CancelledOrderDetails";

import PendingQuotation from "./pages/PendingQuotation";
import PendingOrderDetails from "./pages/PendingOrderDetails";


/** we will check if local storage says it has logged in, if no redirect to login */


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    getIsLoggedIn();
  }, [isLoggedIn]);


  async function getIsLoggedIn() {
    var isLoggedIn = await reactLocalStorage.get("isLoggedIn")
    if (isLoggedIn == "ADMIN_YES") {
      setIsLoggedIn(true)
    }
  }
  return (
    <>

      <Router>
        { isLoggedIn && <Navbar /> }
        <Switch>
          <Route path='/' exact component={isLoggedIn ? Dashboard : Login} />
          <Route path='/login' exact component={isLoggedIn ? Dashboard : Login} />
          <Route path="/addservice" component={isLoggedIn ? AddMainService : Login}></Route>
          <Route path="/services" component={isLoggedIn ? Services : Login}></Route>
          <Route path="/servicesAdd" component={isLoggedIn ? ServicesAdd : Login}></Route>
          <Route path="/subCategoryEdit" component={isLoggedIn ? SubCategoryEdit : Login}></Route>

          <Route path="/servicesEdit" component={isLoggedIn ? ServicesEdit : Login}></Route>
          <Route path="/servicesDelete" component={isLoggedIn ? ServicesDelete : Login}></Route>

          <Route path="/locations" component={isLoggedIn ? Locations : Login}></Route>
          <Route path="/wallet" component={isLoggedIn ? Wallet : Login}></Route>
          <Route path="/orders/:id" component={isLoggedIn ? Orders : Login}></Route>
          <Route path="/createOrder" component={isLoggedIn ? CreateOrder : Login}></Route>
          <Route path="/coupon" component={isLoggedIn ? Coupon : Login}></Route>
          <Route path="/assignedDetails/:id" component={isLoggedIn ? AssignedOrderDetails : Login}></Route>
          <Route path="/completedDetails/:id" component={isLoggedIn ? CompletedOrderDetails : Login}></Route>
          <Route path="/cancelledDetails/:id" component={isLoggedIn ? CancelledOrderDetails : Login}></Route>

          <Route path="/pendingDetails/:id" component={isLoggedIn ? PendingOrderDetails : Login}></Route>
          <Route path="/quotation" component={isLoggedIn ? PendingQuotation : Login}></Route>
          <Route path="/users" component={isLoggedIn ? RegUsers : Login}></Route>
          <Route path="/vendors" component={isLoggedIn ? RegVendors : Login}></Route>
          <Route path="/vendorDetails/:id" component={isLoggedIn ? RegVendorDetails : Login}></Route>
          <Route path="/userDetails/:id" component={isLoggedIn ? RegUserDetails : Login}></Route>

        </Switch>
      </Router>

    </>
  );
}

export default App;


/* notes
status of vendor dash given service and pending service
0 v assign pending
1 complete given service
2 on review
4 cancelled
7 redeem request submitted
8 redeem request approved


*/