import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./PendingOrder.css"
import { Table, Row, Col, Modal, Button } from 'react-bootstrap';

const AdminPendingOrder = () => {
  const [allServices, SetServiceHistorys] = useState([]);
  const [query, setQuery] = useState("");

  const [page, setPage] = useState({
    currentPage: null,
    lastPage: null
  });

  useEffect(() => {
    fetchItem(1);
  }, []);
  const history = useHistory();


  const fetchItem = async (page) => {
    fetch("https://backend.amaderservice.com/api/allserviceadmin?page=" + page)
      .then((response) => response.json())
      .then((data) => {
        SetServiceHistorys(data.data.reverse())
        let obj = {
          currentPage: page,
          lastPage: data.last_page
        }
        setPage(obj)
      })
  };

  function searchHandler() {
    const link = "https://backend.amaderservice.com/api/"
    fetch(link + "searchallserviceadmin?search=" + query)
      .then((response) => response.json())
      .then((data) => {
        SetServiceHistorys(data.data.reverse())
        let obj = {
          currentPage: 1,
          lastPage: data.last_page
        }
        setPage(obj)
      })

  }
  return (
    <div>
      <Row style={{ width: "100%", height: 50, backgroundColor: "#060b26", margin: 0, }}>
        <Col style={{ cursor: "pointer", textAlign: "center", backgroundColor: "#ffffff20" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>All Services</h4>
        </Col>
        <Col onClick={() => history.push('/servicesAdd')} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Add Service</h4>
        </Col>
        <Col onClick={() => history.push("/subCategoryEdit")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Edit Sub Category</h4>
        </Col>
        <Col  onClick={() => history.push("/servicesEdit")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Edit Service</h4>
        </Col>
        <Col onClick={() => history.push("/servicesDelete")} style={{ cursor: "pointer", textAlign: "center", backgroundColor: "" }}>
          <h4 style={{ paddingTop: 10, color: "white" }}>Delete Service</h4>
        </Col>
      </Row>  
      
      
        <div style={{ margin: 20 }}>
          {/* SEARCH AND PAGINATION START */}
<Row style={{ width: "100%", margin: 0, padding: 0 }}>
          <Col style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>
            <h4 style={{ padding: 0, color: "#060b26", textAlign: "left", }}>Services</h4>

          </Col>

          <Col style={{ cursor: "pointer", textAlign: "right", padding: 0, marginTop: -5 }}>
            <form onSubmit={() => searchHandler()}>
              <input
                type="text"
                class="form-control"
                id=""
                style={{ margin: 0 }}
                placeholder="Search..."
                value={query}
                onChange={(event) => setQuery(event.target.value)}
              ></input>
            </form>

          </Col>

          <Col style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>
            <Row style={{ width: "100%", margin: 0, padding: 0 }}>

              <Col onClick={() => {
                let obj = {
                  currentPage: page.currentPage - 1,
                  lastPage: page.lastPage
                }

                if (page.currentPage !== 1) {

                  setPage(obj)
                  fetchItem(page.currentPage - 1)

                }
              }} style={{ cursor: "pointer", textAlign: "right", padding: 0 }}>

                <h4 className="paginationButton" style={{ padding: 0, textAlign: "right", }}>&#9664;</h4>
              </Col>

              <Col style={{ cursor: "pointer", textAlign: "center", padding: 0 }}>

                <h4 style={{ padding: 0, textAlign: "center", color: "#060b26" }}>Page {page.currentPage} of {page.lastPage}</h4>
              </Col>

              <Col onClick={() => {
                let obj = {
                  currentPage: page.currentPage + 1,
                  lastPage: page.lastPage
                }
                if (page.currentPage !== page.lastPage) {

                  setPage(obj)
                  fetchItem(page.currentPage + 1)

                }
              }}
                style={{ cursor: "pointer", textAlign: "left", padding: 0 }}>

                <h4
                  className="paginationButton" style={{ padding: 0, textAlign: "left" }}>&#9654;</h4>
              </Col>

            </Row>
          </Col>


        </Row>
        {/* SEARCH AND PAGINATION END */}    
          <Table responsive>
            <thead>
              <tr>
                <th>Category</th>
                <th>Sub Category</th>
                <th>Service</th>
                <th>Sub Service</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {allServices.map((item) => (
                <tr>
                  <td>{item.firstlayer}</td>
                  <td >{item.secondlayer}</td>
                  <td >{item.thirdlayer}</td>
                  <td >{item.forthlayer}</td>
                  <td >৳ {item.price}</td>


                </tr>
              ))}
            </tbody>
          </Table>
        </div>
          

    </div>
  );
};

export default AdminPendingOrder;
